import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import TextArea from "../../component/Common/TextArea";
import Loader from "../../component/Common/Loader";
import "../../css/ProjectForm.css";
import { fileUpload } from "../../helpers/fileUpload";
import { useForm } from "../../hooks/useForm";
import { addIcon, removeIcon } from "../../icons";
import { imageProps } from "../../props";
import {
  getInitialProjectState,
  projectAddressList,
  projectValidate,
} from "../../helpers/projects";

import {
  createProject,
  updateProjectList,
} from "../../store/slice/projectSlice";

const CreateProjectPage = ({ projectDetails }) => {
  const handleInputChangeLocation = (inputValue, actionMeta) => {
    console.group("Input Changed");
    console.groupEnd();
  };

  let InitialLogoImagePreview;
  let InitialHorizontalFeaturedImagePreview;
  let InitialVerticalFeaturedImagePreview;
  let InitialBannerImagePreview;
  let InitialBrochurePdfPreview;

  if (projectDetails?.brochure) {
    InitialBrochurePdfPreview = `${process.env.REACT_APP_FILE_BASE_URL}/${projectDetails?.brochure}`;
  }

  // if (projectDetails?.logo) {
  //   InitialLogoImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${projectDetails.logo}`;
  // }

  if (projectDetails?.featured_image) {
    InitialHorizontalFeaturedImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${projectDetails.featured_image}`;
  }

  if (projectDetails?.vertical_featured_image) {
    InitialVerticalFeaturedImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${projectDetails.vertical_featured_image}`;
  }

  if (projectDetails?.banner_image) {
    InitialBannerImagePreview = JSON.parse(projectDetails?.banner_image).map(
      (a) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${a}`;
      }
    );
  }

  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const loader = useSelector((state) => state.project.loader);

  const params = useParams();

  const { id } = params;

  const initialFValues = useCallback(() => {
    return getInitialProjectState(
      projectDetails,
      InitialLogoImagePreview,
      InitialBannerImagePreview,
      InitialHorizontalFeaturedImagePreview,
      InitialVerticalFeaturedImagePreview,
      InitialBrochurePdfPreview
    );
  }, [
    projectDetails,
    InitialLogoImagePreview,
    InitialBannerImagePreview,
    InitialHorizontalFeaturedImagePreview,
    InitialVerticalFeaturedImagePreview,
    InitialBrochurePdfPreview,
  ]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return projectValidate(temp, fieldValues, values, setValues, setErrors);
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialFValues,
    true,
    validate
  );
  // const logoRemoveHandler = () => {
  //   setValues({
  //     ...values,
  //     logo: "",
  //     logo_preview: "",
  //   });
  // };

  const horizontalFeaturelogoRemoveHandler = () => {
    setValues({
      ...values,
      horizontal_featured_image: "",
      horizontal_featured_image_preview: "",
    });
  };

  const verticalFeaturelogoRemoveHandler = () => {
    setValues({
      ...values,
      vertical_featured_image: "",
      vertical_featured_image_preview: "",
    });
  };

  const brochurePdfRemoveHandler = () => {
    setValues({
      ...values,
      brochure: "",
      brochure_preview: "",
    });
  };

  const bannerImageHandler = (e) => {
    values.banner_images_error = "";

    const { files } = e.target;

    if (files.length > 0) {
      let images = [];
      for (const key in files) {
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            values.banner_images_error =
              "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            values.banner_images_error =
              "Image size should not be greater than 50MB";
            continue;
          }
          images.push(files[key]);
        }
      }

      const temp = [...values.banner_image_preview];

      for (let i = 0; i < images.length; i++) {
        temp.push(URL.createObjectURL(images[i]));
      }

      setValues({
        ...values,
        banner_image_preview: [...temp],
        banner_image: [...values.banner_image, ...images],
      });
    }
  };

  const imageRemoveHandler = (i) => {
    const imgArr = [...values.banner_image];
    const imgArrPreview = [...values.banner_image_preview];

    imgArr.splice(i, 1);
    imgArrPreview.splice(i, 1);
    setValues({
      ...values,
      banner_image: imgArr,
      banner_image_preview: imgArrPreview,
    });
  };

  const createProjectHandler = async (e) => {
    e.preventDefault();
    setIsPending(true);

    const formData = new FormData();
    // if (typeof values.logo !== "string") {
    //   formData.append("file", values.logo);

    //   if (validate()) {
    //     logoUrl = await fileUpload(formData);
    //   }

    //   formData.delete("file");
    // } else {
    //   logoUrl = values.logo;
    // }

    //feature image
    let horizontalFeaturedImageUrl;
    if (typeof values.horizontal_featured_image !== "string") {
      formData.append("file", values.horizontal_featured_image);
      if (validate()) {
        horizontalFeaturedImageUrl = await fileUpload(formData);
      }
      formData.delete("file");
    } else {
      horizontalFeaturedImageUrl = values.horizontal_featured_image;
    }

    let verticalFeaturedImageUrl;
    if (typeof values.vertical_featured_image !== "string") {
      formData.append("file", values.vertical_featured_image);
      if (validate()) {
        verticalFeaturedImageUrl = await fileUpload(formData);
      }
      formData.delete("file");
    } else {
      verticalFeaturedImageUrl = values.vertical_featured_image;
    }

    let tempBannerImage = [];

    for (let i = 0; i < values.banner_image.length; i++) {
      if (typeof values.banner_image[i] !== "string") {
        formData.append("file", values.banner_image[i]);
        let temp = await fileUpload(formData);
        if (temp) {
          tempBannerImage.push(temp);
        }
        formData.delete("file");
      } else {
        tempBannerImage.push(values.banner_image[i]);
      }
    }

    let pdfUrl;
    if (typeof values.brochure !== "string") {
      formData.append("file", values.brochure);

      pdfUrl = await fileUpload(formData);

      formData.delete("file");
    } else {
      pdfUrl = values.brochure;
    }
    setIsPending(false);
    if (validate()) {
      const data = {
        possession_status: values.possession_status,
        project_type: values.project_type,
        name: values.name.trim(),
        one_line_specification: values.one_line_specification.trim(),
        location: values.location.value,
        // description_title: values.description_title.trim(),
        description: values.description.trim(),
        banner_image: tempBannerImage,
        // logo: logoUrl,
        featured_image: horizontalFeaturedImageUrl,
        vertical_featured_image: verticalFeaturedImageUrl,
        rera_number: values.rera_number.trim(),
        project_contact_number: values.project_contact_number.trim(),
        google_place_id: values.google_place_id.trim(),
        // rate_us_on_google: values.rate_us_on_google,
        // google_reviews: values.google_reviews,
        brochure:pdfUrl, 
      };

      if (id) {
        dispatch(updateProjectList({ data, id }));
      } else {
        dispatch(createProject({ data, navigate }));
      }
    }
  };

  return (
    <>
      {(isPending || loader) && <Loader />}
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className="create_from_input_content">
            <label className="create_from_label">Project Type</label>
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Commercial"
              id="commercial"
              name="project_type"
              value="commercial"
              onChange={handleInputChange}
              checked={values.project_type === "commercial"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Residential"
              id="residential"
              name="project_type"
              value="residential"
              onChange={handleInputChange}
              checked={values.project_type === "residential"}
            />
          </div>

          <div className="create_from_input_content">
            <label className="create_from_label">Possession Status </label>
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Ready Possession"
              id="ready_possession"
              name="possession_status"
              value="ready_possession"
              onChange={handleInputChange}
              checked={values.possession_status === "ready_possession"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Under Construction"
              id="under_construction"
              name="possession_status"
              value="under_construction"
              onChange={handleInputChange}
              checked={values.possession_status === "under_construction"}
            />
          </div>
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Name"
            label="Name"
            id="name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors?.name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter One Line Specification"
            label="One Line Specification"
            id="one_line_specification"
            name="one_line_specification"
            value={values.one_line_specification}
            onChange={handleInputChange}
            error={errors?.one_line_specification || ""}
          />
          <div className={`create_from_input_content`}>
            <label className="create_from_label">Location </label>
            <CreatableSelect
              className="basic-multi-select"
              classNamePrefix="select"
              value={values.location}
              onChange={(e) => handleInputChange(e, "location")}
              onInputChange={handleInputChangeLocation}
              options={projectAddressList}
            />
            {/* <Select
              className="basic-multi-select"
              classNamePrefix="select"
              value={values.location}
              name="location"
              options={projectAddressList}
              id="location"
              onChange={(e) => handleInputChange(e, "location")}
            /> */}
            <span className="err_text">{errors?.location || ""}</span>
          </div>

          <div className="create_from_input_content null"></div>
          {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Description Title"
            label="Description Title"
            id="description_title"
            name="description_title"
            value={values.description_title}
            onChange={handleInputChange}
            error={errors?.description_title || ""}
          /> */}
          {/* <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            errorClassName="err_text"
            type="file"
            label="Logo"
            id="logo"
            name="logo"
            accept="image/png, image/gif, image/jpeg"
            onChange={handleInputChange}
            error={errors?.logo || ""}
            onClick={(e) => (e.target.value = null)}
            imageProps={imageProps(
              values.logo,
              values.logo_preview,
              logoRemoveHandler,
              "logo"
            )}
          /> */}
          <div className="create_from_input_content">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              label="Horizontal Featured Image"
              id="horizontal_featured_image"
              name="horizontal_featured_image"
              accept="image/png,  image/jpeg"
              onChange={handleInputChange}
              error={errors?.horizontal_featured_image || ""}
              onClick={(e) => (e.target.value = null)}
              imageProps={imageProps(
                values.horizontal_featured_image,
                values.horizontal_featured_image_preview,
                horizontalFeaturelogoRemoveHandler,
                "horizontal_featured_image"
              )}
            />
            <p className="create_project_review">
              1280 x 720 (16 : 9),The maximum size for an upload is 1 MB
            </p>
          </div>
          <div className="create_from_input_content">
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              label="Vertical Featured Image"
              id="vertical_featured_image"
              name="vertical_featured_image"
              accept="image/png,  image/jpeg"
              onChange={handleInputChange}
              error={errors?.vertical_featured_image || ""}
              onClick={(e) => (e.target.value = null)}
              imageProps={imageProps(
                values.vertical_featured_image,
                values.vertical_featured_image_preview,
                verticalFeaturelogoRemoveHandler,
                "vertical_featured_image"
              )}
            />
            <p className="create_project_review">
              720 x 1280 (9 : 16),The maximum size for an upload is 1 MB
            </p>
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Banner Images</h2>
            <Input
              className="project_edit_media_input_file"
              errorClassName="err_text"
              type="file"
              label="Add Banner Images"
              labelOtherProps={addIcon}
              id="banner_image"
              name="banner_image"
              multiple={true}
              accept="image/png, image/jpeg"
              onChange={bannerImageHandler}
              onClick={(e) => (e.target.value = null)}
              imageProps={
                <div className="project_edit_media_image_section">
                  {values.banner_image_preview &&
                    values.banner_image_preview.map((image, i) => {
                      return (
                        <div
                          key={i}
                          className="project_edit_media_image_column "
                        >
                          <img
                            src={image}
                            width="300"
                            height="300"
                            alt="Banner img"
                            key={i}
                          />
                          <button
                            className="image_gallery_remove_btn"
                            onClick={() => imageRemoveHandler(i)}
                          >
                            <span></span>
                          </button>
                        </div>
                      );
                    })}
                </div>
              }
            />
            <p className="create_project_review">
              720 x 1000 (0.72 : 1),The maximum size for an upload in Images is
              1 MB
            </p>
            {values.banner_images_error && (
              <span className="err_text">{values.banner_images_error}</span>
            )}
          </div>

          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label rera_number"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Rera Registration Number"
            label="Rera Registration Number"
            id="rera_number"
            name="rera_number"
            value={values.rera_number}
            onChange={handleInputChange}
            error={errors?.rera_number || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="tel"
            placeholder="Enter Project Contact"
            label="Project Contact"
            id="project_contact_number"
            name="project_contact_number"
            value={values.project_contact_number}
            onChange={handleInputChange}
            error={errors?.project_contact_number || ""}
            maxLength={10}
          />

          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Description"
            label="Description"
            id="description"
            name="description"
            value={values.description}
            onChange={handleInputChange}
            error={errors?.description || ""}
          />
          {/* <div className="create_from_input_content">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Link"
              label="Rate us on Google"
              id="rate_us_on_google"
              name="rate_us_on_google"
              value={values.rate_us_on_google}
              onChange={handleInputChange}
              // error={errors?.rate_us_on_google || ""}
            />

            <p className="create_project_review">
              Ex :-
              https://search.google.com/local/writereview?placeid=ChIJ95Dv0wlS4DsRmVPxeJ4urH4
            </p>
          </div> */}
          <div className="create_from_input_content">
            <Input
              className="create_from_input_content null"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Google Place Id"
              label="Google Place Id"
              id="google_place_id"
              name="google_place_id"
              value={values.google_place_id}
              onChange={handleInputChange}
              error={errors?.google_place_id || ""}
            />

            <p className="create_project_review">
              Ex :- ChIJ95Dv0wlS4DsRmVPxeJ4urH4
            </p>
          </div>
          <div className="project_edit_detail_column_content amenieties_file_select_content col_span_2">
            <Input
              className="project_edit_detail_column_content amenieties_file_select_content"
              labelClassName="select_amenieties_file_btn"
              inputClassName=""
              errorClassName="err_text"
              type="file"
              label="Choose Pdf"
              id="brochure"
              name="brochure"
              onChange={handleInputChange}
              labelOtherProps={addIcon}
              labelHeading={<h2>Brochure Pdf </h2>}
              accept={"application/pdf"}
              error={errors?.brochure || ""}
            />
            <p className="create_project_review">
              The maximum size for an upload 50 MB
            </p>
            {values.brochure && (
              <>
                <Button
                  buttonClassName="remove_pdf_btn"
                  onClick={brochurePdfRemoveHandler}
                  text="Remove Pdf"
                  other={removeIcon}
                />
                <iframe
                  title="ampp"
                  src={values.brochure_preview}
                  type="application/pdf"
                  className="profile_edit_iframe"
                />
              </>
            )}
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={createProjectHandler}
          text={id ? "Edit Project" : "Create Project"}
        />
      </div>
    </>
  );
};

export default CreateProjectPage;
