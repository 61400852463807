/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getprojectId } from "../../store/slice/projectSlice";
import Button from "../../component/Common/Button";
import {
  getHandoverChecklist,
  handoverToCustomerProperty,
} from "../../store/slice/handoverChecklistSlice";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";

const AssignHandoverChecklist = ({ isOpen, copiedHandoverData, handoverData }) => {
  const handoverchecklist = useSelector(
    (state) => state?.handoverChecklist?.handoverchecklist?.data
  );
  const { project } = useSelector((state) => state.project);
  const params = useParams();
  const { id } = params;
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [handoverOptions, setHandoverOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [handoverTitles, setHandoverTitles] = useState([]);
  const [selectedTowers, setSelectedTowers] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState({});
  const [projectData, setProjectData] = useState({ towerName: [] });

  useEffect(() => {
    fetchHandoverChecklist(1)
    dispatch(getprojectId(id));
  }, []);

  useEffect(() => {
    if (project) {
      try {
        setProjectData({
          ...projectData,
          towerName: JSON.parse(project.tower_names || []),
        });
      } catch (error) {
        console.error("Error parsing project data:", error);
      }
    }
    if (copiedHandoverData && copiedHandoverData.handovers) {
      const copiedTitles = copiedHandoverData?.handovers?.map((handover) =>
        String(handover.title)
      );
      setHandoverTitles(copiedTitles);
    }
  }, [project, copiedHandoverData]);

  const handleTowerCheck = (towerName) => {
    const selectedTower = projectData.towerName.find(
      (tower) => tower.name === towerName
    );

    if (
      !selectedTower ||
      !selectedTower.properties ||
      selectedTower.properties.length === 0
    ) {
      toast.error("No properties available for this tower.", {
        autoClose: 1500,
      });
      return;
    }

    setSelectedTowers((prev) => {
      if (prev.includes(towerName)) {
        const newSelectedTowers = prev.filter((name) => name !== towerName);
        const newSelectedProperties = { ...selectedProperties };
        delete newSelectedProperties[towerName];
        setSelectedProperties(newSelectedProperties);
        return newSelectedTowers;
      } else {
        return [...prev, towerName];
      }
    });
  };
  useEffect(() => {
    setData({
      handover_ids: handoverchecklist
        ?.filter((item) => handoverTitles?.includes(item.title))
        ?.map((item) => String(item.id)),
      customer_property_ids: Object.values(selectedProperties)
        .flat()
        .map((item) => item.value),
    });
  }, [handoverTitles, handoverchecklist, selectedProperties]);

  const fetchHandoverChecklist = async (newPage) => {
    if (!hasMore || loading) return; // Stop if no more data or already loading
    setLoading(true);

    const response = await dispatch(
      getHandoverChecklist({ project_id: id, limit: 20, page: newPage })
    ).unwrap();

    if (response?.data.length > 0) {
      setHandoverOptions((prev) => [
        ...prev,
        ...response?.data
          ?.filter(
            (checklist) =>
              checklist?.handover_status === true &&
              checklist?.project_name === project?.name
          )
          ?.map((item) => ({
            value: String(item.id),
            label: item.title,
          })),
      ]);
    } else {
      setHasMore(false); // No more data available
    }
    setLoading(false);
  };

  const handleHandoverTitleChange = async (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "select_all")) {
      try {
        const response = await dispatch(getHandoverChecklist({ project_id: id })).unwrap();
        const handoverChecklistOptions = response?.data
          ?.filter(
            (checklist) =>
              checklist?.handover_status === true &&
              checklist?.project_name === project?.name
          )
          ?.map((item) => ({
            value: String(item.id),
            label: item.title,
          }));
        setHandoverOptions(handoverChecklistOptions);
        setHandoverTitles(handoverChecklistOptions?.map((option) => option.label));
      } catch (error) {
        console.log("Error Fetching possession checklist", error);

      }
    } else if (selectedOptions.some((option) => option.value === "deselect_all")) {
      setHandoverTitles([]);
    } else {
      setHandoverTitles(selectedOptions.map((option) => option.label));
    }
  };

  const handleSelectAllProperties = (
    towerName,
    selectedOptions,
    towerProperties
  ) => {
    if (selectedOptions.some((option) => option.value === "select_all")) {
      if (selectedProperties[towerName]?.length === towerProperties.length) {
        setSelectedProperties((prev) => ({
          ...prev,
          [towerName]: [],
        }));
      } else {
        setSelectedProperties((prev) => ({
          ...prev,
          [towerName]: copiedHandoverData ? towerProperties
            .filter((item) => item?.user_id !== null && item?.handover_status === null && item?.id !== copiedHandoverData?.id)
            .map((property) => ({
              value: property.id,
              label: property.property_number,
            })) : towerProperties
              .filter((item) => item?.user_id !== null && item?.handover_status === null)
              .map((property) => ({
                value: property.id,
                label: property.property_number,
              }))
        }));
      }
    } else {
      setSelectedProperties((prev) => ({
        ...prev,
        [towerName]: selectedOptions || [],
      }));
    }
  };

  const handleStartHandover = async (e) => {
    e.preventDefault();
    if (
      data?.handover_ids.length > 0 &&
      data?.customer_property_ids.length > 0
    ) {
      try {
        await dispatch(handoverToCustomerProperty({ id, data }));
        toast.success("Possession Successful", {
          autoClose: 1000,
        });
        isOpen();
      } catch (error) {
        console.log(error);
        toast.error("Possession Failed");
      }
    } else {
      toast.error("Please select at least one tower and one possession item", {
        autoClose: 2000,
      });
    }
  };

  const handleScrollBottom = (e) => {
    if (hasMore && !loading && handoverchecklist.length === 20) {
      setPage((prev) => prev + 1);
      fetchHandoverChecklist(page + 1);
    }
  };

  return (
    <>
      <div className="project_edit_main_content center_table_body">
        <button
          className="comman_btn"
          style={{ marginBottom: "15px" }}
          onClick={isOpen}
        >
          Back to Possession
        </button>
      </div>
      <div className="create_from_row">
        <h3>Possession Checklist for {project?.name}</h3>
        <div className="contact_property_main_wrapper">
          <div
            className="create_from_checkbox_content"
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              marginBottom: "20px",
            }}
          >
            <CreatableSelect
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={
                handoverOptions?.length === 0
                  ? "No Checklist Available"
                  : "Select Possession Items..."
              }
              isMulti
              options={
                handoverOptions?.length > 0 ? (handoverTitles.length !== handoverOptions.length
                  ? [
                    { value: "select_all", label: "Select All" },
                    ...handoverOptions,
                  ] : [{ value: "deselect_all", label: "Remove All" }])
                  : [{ value: "no_checklist", label: "No Checklist Available" }]
              }
              onChange={handleHandoverTitleChange}
              value={handoverTitles.map(title => ({ value: title, label: title }))}
              menuPortalTarget={document?.body}
              isDisabled={handoverOptions?.length === 0} // Disable select if no options
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),

              }}
              onMenuScrollToBottom={handleScrollBottom}
            />
            {/* {handoverTitles.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                <h4>Selected Handovers: </h4>
                {handoverTitles.map((title, index) => (
                  <div key={index}>{title},</div>
                ))}
              </div>
            )} */}
          </div>
          <hr />
          <h3 style={{ margin: "15px 0px 15px 0px" }}>Tower Names</h3>

          {projectData?.towerName?.map((tower, index) => (
            <div key={index} className="tower-container">
              <label className="checkbox-label" style={{ marginBottom: "8px" }}>
                <input
                  type="checkbox"
                  checked={selectedTowers.includes(tower.name)}
                  onChange={() => handleTowerCheck(tower.name)}
                />
                <span>{tower.name}</span>
              </label>
              {selectedTowers.includes(tower.name) && (
                <div className="property_tower_wrapper">
                  <div className="property_tower_label">
                    <label className="property_label">{tower?.name}</label>
                  </div>
                  <div className="property_tower_select">
                    <label className="create_from_label">Properties</label>
                    <CreatableSelect
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Properties..."
                      isMulti
                      options={copiedHandoverData
                        ? [
                          { value: "select_all", label: "Select All" },
                          ...tower?.properties
                            .filter((item) => item?.user_id !== null && item?.handover_status === null && item?.id !== copiedHandoverData?.id)
                            .map((property) => ({
                              value: property.id,
                              label: property.property_number,
                            })),
                        ]
                        : [
                          { value: "select_all", label: "Select All" },
                          ...tower?.properties
                            .filter((item) => item?.user_id !== null && item?.handover_status === null
                            )
                            .map((property) => ({
                              value: property.id,
                              label: property.property_number,
                            })),
                        ]
                      }
                      onChange={(selectedOptions) =>
                        handleSelectAllProperties(
                          tower.name,
                          selectedOptions,
                          tower.properties
                        )
                      }
                      menuPortalTarget={document?.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                          height: 20,
                          minHeight: 20,
                        }),
                      }}
                      value={selectedProperties[tower.name] || []}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <Button
          className="create_from_bottom_btn col_span_2"
          buttonClassName="comman_btn"
          onClick={handleStartHandover}
          text="Start Possession"
        />
      </div>
    </>
  );
};

export default AssignHandoverChecklist;
