import React from "react";
import AmenitiesForm from "./AmenitiesForm";
import OnSiteActualForm from "./OnSiteActualForm";
import SpecificationForm from "./SpecificationForm";
import TowersNameForm from "./TowersNameForm";
import LegalDocumentsForm from "./LegalDocumentsForm";
// import BenefitsForm from "./BenefitsForm";
import CreateProjectPage from "../../../pages/Project/CreateProjectPage";
// import KeyFeaturesForm from "./KeyFeaturesForm";
import MediasForm from "./MediasForm";
import AddressesForm from "./AddressesForm";
import RewardPointsForm from "./RewardPointsForm";
import LayoutAndPlan from "./LayoutAndPlan";
import PrivilegeForm from "./PrivilegeForm";
import ContactForm from "./ContactForm";
import NotificationContact from "./NotificationContact";
import HandoverPropertyForm from "./HandoverPropertyForm";
import AssignHandoverChecklist from "../../../pages/HandoverChecklist/AssignHandoverChecklist";

const ProjectForms = ({ menu, projectDetails }) => {
  return (
    <>
      {menu === "basicInfo" && (
        <CreateProjectPage projectDetails={projectDetails} />
      )}
      {/* {menu === "keyFeatures" && (
        <KeyFeaturesForm projectDetails={projectDetails} />
      )} */}
      {menu === "amenities" && (
        <AmenitiesForm projectDetails={projectDetails} />
      )}
      {menu === "specifications" && (
        <SpecificationForm projectDetails={projectDetails} />
      )}
      {menu === "medias" && <MediasForm projectDetails={projectDetails} />}

      {/* {menu === "benefits" && <BenefitsForm projectDetails={projectDetails} />} */}
      {menu === "privilege" && (
        <PrivilegeForm projectDetails={projectDetails} />
      )}
      {menu === "contact" && <ContactForm projectDetails={projectDetails} />}
      {menu === "towerNames" && (
        <TowersNameForm projectDetails={projectDetails} />
      )}

      {menu === "handover" && (
        <HandoverPropertyForm projectDetails={projectDetails} />
      )}
      {menu === "assignHandover" && <AssignHandoverChecklist />}

      {menu === "addresses" && (
        <AddressesForm projectDetails={projectDetails} />
      )}

      {menu === "rewardsPoints" && (
        <RewardPointsForm projectDetails={projectDetails} />
      )}
      {menu === "onsiteActuals" && (
        <OnSiteActualForm projectDetails={projectDetails} />
      )}
      {/* {menu === "layoutAndPlan" && (
        <LayoutAndPlan projectDetails={projectDetails} />
      )} */}
      {menu === "legalDocuments" && (
        <LegalDocumentsForm projectDetails={projectDetails} />
      )}
      {menu === "notificationContact" && (
        <NotificationContact projectDetails={projectDetails} />
      )}
    </>
  );
};

export default ProjectForms;
