import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

//createProject
export const createProject = createAsyncThunk(
  "createProject",

  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/projects/create",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Project Create Successfully !", {
          autoClose: 2000,
        });
        data.navigate(routes.projects);
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);
//getProjectList
export const getProjectList = createAsyncThunk(
  "getProjects",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/projects/get/list?possession_status=&project_type=&location=&name=&complaint=${data?.complaint
        }&admin=${data?.admin || ""}`
      );
      return response.data;
    } catch (error) {
      console.log("err", error.response);
    }
  }
);
//getFeaturedProjectList
export const getFeaturedProjectList = createAsyncThunk(
  "getFeaturedProjectList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/projects/get/featured_project_list?featured=${data.featured}`
      );

      return { [data.featured]: response.data };
    } catch (error) {
      console.log("err", error.response);
    }
  }
);
//getprojectId
export const getprojectId = createAsyncThunk("getprojectId", async (id) => {
  try {
    const response = await Axios.get(`/projects/get/project-detail/${id}`);

    return response.data;
  } catch (error) {
    console.log("err", error.response);
  }
});
//clearProject
export const clearProject = createAsyncThunk("clearProjects", () => {
  return;
});
//updateProjectList
export const updateProjectList = createAsyncThunk(
  "updateProjects",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/projects/update/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.toast !== 1) {
        toast.info("Project Update Successfully !", {
          autoClose: 2000,
        });

        if (!data.cb) {
          thunkAPI.dispatch(getprojectId(data.id));
          return response.data;
        } else {
          data.cb(null, response.data);
        }
      }
    } catch (error) {
      console.log("updateProjectError", error.response);
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      return data.cb(error);
    }
  }
);

export const getTowersByProject = createAsyncThunk(
  "getTowersByProject",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/projects/get/tower-names/${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("err", error.response);
    }
  }
);

const projectSlice = createSlice({
  name: "createProject",
  initialState: {
    createProject: null,
    updateProject: null,
    projects: [],
    featuredProjects: [],
    unFeaturedProjects: [],
    towerList: [],
    loader: false,
    error: "",
    project: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    //createProject
    builder.addCase(createProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.loader = false;
      state.createProject = action.payload;
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //update project
    builder.addCase(updateProjectList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateProjectList.fulfilled, (state, action) => {
      state.loader = false;
      state.updateProject = action.payload;
    });
    builder.addCase(updateProjectList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getProjectList
    builder.addCase(getProjectList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getProjectList.fulfilled, (state, action) => {
      state.loader = false;
      state.projects = action.payload;
    });
    builder.addCase(getProjectList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getFeaturedProjectList
    builder.addCase(getFeaturedProjectList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFeaturedProjectList.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload && action.payload["1"]) {
        state.featuredProjects = action.payload["1"];
      } else {
        state.unFeaturedProjects = action.payload["0"];
      }
    });
    builder.addCase(getFeaturedProjectList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getprojectId
    builder.addCase(getprojectId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getprojectId.fulfilled, (state, action) => {
      state.loader = false;
      state.project = action.payload;
    });
    builder.addCase(getprojectId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //clearProject
    builder.addCase(clearProject.fulfilled, (state, action) => {
      state.project = null;
      state.createProject = null;
    });
    //getTowerListById
    builder.addCase(getTowersByProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTowersByProject.fulfilled, (state, action) => {
      state.loader = false;
      state.towerList = action.payload;
    });
    builder.addCase(getTowersByProject.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
// export const { clearProject } = projectSlice.actions;
export default projectSlice.reducer;
