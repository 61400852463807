/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getRewardsHistory } from "../../store/slice/userSlice";
import Select from "react-select";
import { useForm } from "../../hooks/useForm";
import Pagination from "../../component/Pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import { searchIcon } from "../../icons";

const rewardStatusOptions = [
  { value: "", label: "All" },
  { value: "credited", label: "Credited" },
  { value: "debited", label: "Debited" },
];

const RewardHistoryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const initialFValues = {
    rewardStatus: "",
    phone_number: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    navigate(`${routes.rewardHistory}/page/1`);
    if (+page_number === 1) {
      dispatch(
        getRewardsHistory({
          reward_status: values.rewardStatus.value,
          phoneNumber: values.phone_number,
        })
      );
    }
  }, [dispatch, values.rewardStatus]);

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.rewardHistory}/page/1`);
    setValues({ ...values, phone_number: e.target.value });
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    dispatch(
      getRewardsHistory({
        start: page_num,
        reward_status: values.rewardStatus.value,
        phoneNumber: e.target.value,
        limit: 10,
      })
    );
  };

  const rewardHistory = useSelector((state) => state.user.rewardHistory?.data);
  const totalCount = useSelector(
    (state) => state.user.rewardHistory?.totalCount
  );

  const onPageChange = (page_number) => {
    dispatch(
      getRewardsHistory({
        start: page_number,
        reward_status: values.rewardStatus.value,
        phoneNumber: values.phone_number,
        limit: 10,
      })
    );
  };

  return (
    <div className="project_edit_main_content">
      <div className="user_data_search_select_box_row">
        <div className="user_data_search">
          <Input
            inputClassName="user_data_search_input"
            type="search"
            placeholder="Search by Mobile-number"
            id="phone_number"
            name="phone_number"
            maxLength={10}
            value={values.phone_number}
            onChange={mobileUserHandler}
          />
          <Button buttonClassName="user_data_search_btn" text={searchIcon} />
        </div>
        <div className="user_data_select lead_status-options">
          <Select
            placeholder="Reward Status..."
            value={values.rewardStatus}
            onChange={(e) => handleInputChange(e, "rewardStatus")}
            className="basic-multi-select"
            classNamePrefix="select"
            name="rewardStatus"
            options={rewardStatusOptions}
          />
        </div>
      </div>
      <div className="custom_data_table_content">
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              <th className="custom_data_table_heading">Name</th>
              <th className="custom_data_table_heading">Phone number</th>
              <th className="custom_data_table_heading">Project Name</th>
              <th className="custom_data_table_heading">Reward Points</th>
              <th className="custom_data_table_heading">Lead Status</th>
              <th className="custom_data_table_heading">Reward Status</th>
              <th className="custom_data_table_heading">Date</th>
              <th className="custom_data_table_heading">Order Id</th>
              <th className="custom_data_table_heading">Coupon Name</th>
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {rewardHistory &&
              rewardHistory?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      {item?.first_name} {item?.last_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.phone_number}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.project_name}
                    </td>
                    <td
                      className={`custom_data_table_item table_item ${
                        item?.reward_status === "credited"
                          ? "credited_points"
                          : "debited_points"
                      }`}
                    >
                      {item?.reward_points}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.lead_status && (item?.lead_status).toUpperCase()}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.reward_status &&
                        (item?.reward_status).toUpperCase()}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {moment(item?.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.reward_detail &&
                        JSON.parse(item?.reward_detail)?.order_id}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.reward_detail &&
                        JSON.parse(item?.reward_detail)?.coupon_name}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
    </div>
  );
};

export default RewardHistoryPage;
