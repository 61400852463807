import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import TextArea from "../../component/Common/TextArea";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { updateFeedback } from "../../store/slice/complaintSlice";

const EditFeedbackPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = params;

  const initialFValues = {
    reply:
      location.state.feedbackState === null ? "" : location.state.feedbackState,
    reply_error: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const updateHandler = (e) => {
    e.preventDefault();

    if (!values.reply) {
      setValues({ ...values, reply_error: "Feedback response is required!" });
      return;
    }
    const data = {
      reply: values.reply,
    };
    dispatch(
      updateFeedback({
        data,
        id,
        suggestion: "1",
      })
    );
    navigate(`${routes.feedBack}/page/${location.state.page_number}`, {
      state: { value: location.state.value },
    });
  };

  return (
    <>
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.feedBack}/page/${location.state.page_number}`}
          state={{ value: location.state.value }}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <h2 className="col_span_2">Feedback</h2>
          <div className="create_from_input_content col_span_2">
            <TextArea
              className="create_from_input_content col_span_2"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter reply"
              label="Reply"
              id="reply"
              name="reply"
              value={values.reply}
              onChange={handleInputChange}
              error={values?.reply_error || ""}
            />
          </div>
          <Button
            className="creat_edit_project_btn_row  col_span_2"
            buttonClassName="comman_btn"
            onClick={updateHandler}
            text="Update"
          />
        </div>
      </div>
    </>
  );
};

export default EditFeedbackPage;
