import React from "react";
import "../../css/component.css";

const Navbar = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer_section">
      <p className="footer_copyright_text">
        Copyright {year}-{year - 1999} © sangini All rights reserved.
      </p>
      <p className="footer_version_text">Version 3.1.0</p>
    </footer>
  );
};

export default Navbar;
