import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

//add complaint reply

export const createComplaintReply = createAsyncThunk(
  "createComplaintReply",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/add-complaint-reply",
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Message Send Successfully !", {
          autoClose: 2000,
        });
        return data.cb(null, response.data);
        // data.navigate(`${routes.contacts}/page/1`);
      }
      return response.data;
    } catch (error) {
      console.log("createContactError", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const editComplaintReply = createAsyncThunk(
  "editComplaintReply",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/users/update/complaint-reply/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Message Updated Successfully !", {
          autoClose: 2000,
        });
        return data.cb(null, response.data);
        // data.navigate(`${routes.contacts}/page/1`);
      }
      return response.data;
    } catch (error) {
      console.log("createContactError", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//getComplaintDetail
export const getComplaintDetail = createAsyncThunk(
  "getComplaintDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/complaints?phone_number=${
          data.phone_number ? data.phone_number : ""
        }&status=${data.status}&req_type=${data.req_type}&start=${
          data.start || 0
        }&limit=${data.limit || 10}&action=${data.export}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getComplaintError", error.response);
    }
  }
);
export const getComplaintDetailForCSV = createAsyncThunk(
  "getComplaintDetailForCSV",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/complaints?phone_number=${
          data.phone_number ? data.phone_number : ""
        }&status=${data.status}&req_type=${data.req_type}&action=exportData`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getComplaintError", error.response);
    }
  }
);
//getComplaintDetailByComplaintId
export const getComplaintDetailByComplaintId = createAsyncThunk(
  "getComplaintDetailByComplaintId",
  async (id, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/complaint/${id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getComplaintDetailError", error.response);
    }
  }
);

//getComplaintDetailByUserId
export const getComplaintDetailByUserId = createAsyncThunk(
  "getComplaintDetailByUserId",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/complaints?user_id=${data.userId}&req_type=${data.reqType}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getComplaintDetailError", error.response);
    }
  }
);

//updateComplaint
export const updateComplaint = createAsyncThunk(
  "updateComplaint",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/users/update/complaint/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Complaint Updated Successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.complaints}/page/${data.page_number}`, {
          state: { value: data.value },
        });
      }
      return response.data;
    } catch (error) {
      console.log("updateComplaintErr", error.response);
    }
  }
);
//updateFeedback
export const updateFeedback = createAsyncThunk(
  "updateFeedback",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/users/update/complaint/${data.id}?suggestion=${
          data.suggestion ? data.suggestion : "0"
        }`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Feedback Updated Successfully", {
          autoClose: 2000,
        });
      }
      if (data.cb) {
        return data.cb(response.data);
      }
      return response.data;
    } catch (error) {
      data.cb(null, error);
    }
  }
);
//clearComplaint
export const clearComplaint = createAsyncThunk("clearComplaint", () => {
  return;
});
//createSlice
const complaintSlice = createSlice({
  name: "complaint",
  initialState: {
    complaintDetails: [],
    complaintDetailId: null,
    complaintDetailUserId: null,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    // getComplaintDetail;
    builder.addCase(getComplaintDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getComplaintDetail.fulfilled, (state, action) => {
      state.complaintDetails = action.payload;
      state.loader = false;
    });
    builder.addCase(getComplaintDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getComplaintDetailByComplaintId
    builder.addCase(getComplaintDetailByComplaintId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(
      getComplaintDetailByComplaintId.fulfilled,
      (state, action) => {
        state.complaintDetailId = action.payload;
        state.loader = false;
      }
    );
    builder.addCase(
      getComplaintDetailByComplaintId.rejected,
      (state, action) => {
        state.loader = false;
        state.error = action.payload;
      }
    );

    //getComplaintDetailByUserId
    builder.addCase(getComplaintDetailByUserId.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getComplaintDetailByUserId.fulfilled, (state, action) => {
      state.complaintDetailUserId = action.payload;
      state.loader = false;
    });
    builder.addCase(getComplaintDetailByUserId.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //clearComplaint
    builder.addCase(clearComplaint.fulfilled, (state, action) => {
      state.loader = false;
      state.complaintDetailId = null;
    });
  },
});

export default complaintSlice.reducer;
