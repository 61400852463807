import React, { useEffect, useRef, useState } from "react";
import Button from "../../Common/Button";
import { copyIcon, editIcon, searchIcon, viewIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getAssignHandoverChecklist,
  getAssignHandoverChecklistForCSV,
} from "../../../store/slice/handoverChecklistSlice";
import Select from "react-select";
import { useForm } from "../../../hooks/useForm";
import Input from "../../Common/Input";
import HandoverChecklistDetailModal from "../../../pages/HandoverChecklist/HandoverChecklistDetailModal";
import { CSVLink } from "react-csv";
import AssignHandoverChecklist from "../../../pages/HandoverChecklist/AssignHandoverChecklist";
import EditAssignedHandoverChecklist from "../../../pages/HandoverChecklist/EditAssignedHandoverChecklist";
import Pagination from "../../Pagination/PaginationWithoutUrl";
import { toast } from "react-toastify";
import { updateProjectList } from "../../../store/slice/projectSlice";
import { fileUpload } from "../../../helpers/fileUpload";

const HandoverPropertyForm = ({ projectDetails }) => {
  const location = useLocation();
  const locationValues = location?.state?.locationValues;
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const handoverGuidelineFile = JSON.parse(projectDetails?.handover_guidelines)
  const [data, setData] = useState(null)
  const [isEditAssignOpen, setIsEditAssignOpen] = useState(false);
  const [copyHandoverChecklist, setCopyHandoverChecklist] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const initialFValues = {
    phone_number: locationValues?.phone_number
      ? locationValues?.phone_number
      : "",
    status: locationValues?.status ? locationValues?.status : "ALL",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );
  const handoverStatusOptions = [
    { value: "ALL", label: "ALL" },
    { value: "PENDING", label: "PENDING" },
    { value: "STARTED", label: "STARTED" },
    { value: "ACCEPTED", label: "ACCEPTED" },
    { value: "REJECTED", label: "REJECTED" },
  ];
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const csvInstance = useRef();
  const [csv, setCSV] = useState(false);
  const assignHandoverChecklist = useSelector(
    (state) => state.handoverChecklist?.assignHandoverChecklist?.data
  );

  const totalCount = useSelector(
    (state) => state.handoverChecklist?.assignHandoverChecklist?.totalCount
  );

  const dispatch = useDispatch();
  const [handoverId, setHandoverId] = useState();
  const [handoverData, setHandoverData] = useState(assignHandoverChecklist);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    const page = pageNum === 0 ? 1 : Math.floor(pageNum / 10) + 1;
    dispatch(
      getAssignHandoverChecklist({
        id,
        search: values.phone_number,
        page: page,
        limit: 10,
        status: values.status?.value ?? "ALL",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values, pageNum, isAssignOpen, isEditAssignOpen]);

  useEffect(() => {
    if (csv && csvInstance.current && csvInstance.current.link) {
      if (csv.length > 1) {
        setTimeout(() => {
          csvInstance.current.link.click();
          setCSV(false);
        });
      } else {
        toast.error("No Records to Export", {
          autoClose: 1500,
        });
        setCSV(false);
      }
    }
  }, [csv]);

  // const onPageChange = (page) => {
  //   setPageNum(page);
  //   dispatch(
  //     getAssignHandoverChecklist({
  //       id,
  //       search: values.phone_number,
  //       page: page,
  //       limit: 10,
  //       status: values.status?.value ?? "ALL",
  //     })
  //   );
  // };
  const onPageChange = (page) => {
    setPageNum(page);
  };

  const isOpen = (reset = false) => {
    if (handoverGuidelineFile) {
      setIsAssignOpen(!isAssignOpen);
      if (reset) {
        setCopyHandoverChecklist(null); // Clear copied data when closing normally
      }
    } else {
      toast.info("Please Upload the Possession Guideline File", {
        autoClose: 2000
      })
    }
  };

  const isEditOpen = () => {
    setIsEditAssignOpen(!isEditAssignOpen);
  };

  const handleCopyHandoverChecklist = (item) => {
    setCopyHandoverChecklist(item);
    isOpen();
  };

  const mobileUserHandler = (e) => {
    setValues({ ...values, phone_number: e.target.value });
  };

  const showModal = (id, data) => {
    setHandoverId(id);
    setHandoverData(data);
    setShow(true);
  };

  const assignHandoverDownloadHandler = () => {
    dispatch(
      getAssignHandoverChecklistForCSV({
        id,
        search: values.phone_number,
        status: values.status?.value ?? "ALL",
      })
    ).then((res) => {
      setCSV([
        [
          "Contact Number",
          "Delete Status",
          "Handover Status",
          "Handovers",
          "ID",
          "Project ID",
          "Tower Name",
          "User Name",
        ],
        ...res.payload?.data?.map((item) => {
          return [
            item?.contact_number,
            item?.delete_status,
            item?.handover_status,
            item?.handovers?.map((handover) => handover?.title),
            item?.id,
            item?.project_id,
            item?.tower_name,
            item?.user_name,
          ];
        }),
      ]);
    });
  };

  const handleFileChange = async (event) => {
    event.preventDefault()
    const formData = new FormData();
    const file = event.target.files[0];
    let pdfUrl;
    if (file) {
      formData.append("file", file);
      pdfUrl = await fileUpload(formData);
      formData.delete("file");
    } else {
      pdfUrl = file;
    }
    setSelectedFile(file?.name);
    setData({
      handover_guidelines: [
        {
          doc: pdfUrl,
          title: "test",
          isGlobal: true
        }
      ]
    })
  };

  const handleUpload = () => {
    try {
      dispatch(updateProjectList({ data, id }))
      setSelectedFile(null)
      // toast.success("File Uploaded Successfully")
    } catch (error) {
      toast.error(error.message)
      console.log(error);

    }
  };

  const handleViewPdf = () => {
    window.open(`${process.env.REACT_APP_FILE_BASE_URL}/${handoverGuidelineFile[0]?.doc}`, "_blank")
  }


  if (isAssignOpen) {
    return (
      <AssignHandoverChecklist
        isOpen={() => isOpen(true)}
        copiedHandoverData={copyHandoverChecklist}
        handoverData={assignHandoverChecklist}
      />
    );
  } else if (isEditAssignOpen) {
    return (
      <EditAssignedHandoverChecklist
        handoverData={handoverData}
        isEditOpen={isEditOpen}
      />
    );
  } else {
    return (
      <>
        {/* {loader && <Loader />} */}
        <div className="project_edit_main_content center_table_body" style={{ padding: "0px", marginTop: "-32px" }}>
          <div
            className="user_data_search_select_box_row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex" }}>
              <div className="user_data_search">
                <Input
                  inputClassName="user_data_search_input"
                  type="search"
                  placeholder="Search"
                  id="phone_number"
                  name="phone_number"
                  value={values.phone_number}
                  onChange={mobileUserHandler}
                />
                <Button
                  buttonClassName="user_data_search_btn"
                  text={searchIcon}
                />
              </div>
              <div className="user_data_select lead_status-options">
                <Select
                  placeholder="Status"
                  value={values.status}
                  onChange={(e) => handleInputChange(e, "status")}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="status"
                  options={handoverStatusOptions}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "8px", marginBottom: "8.5px", flexWrap: "wrap", flexDirection: "column" }}>
              {/* File Upload Section Moved to Top */}

              <p>Possession Guideline</p>
              <div style={{ textAlign: "left", display: "flex", alignItems: "center", gap: "8px" }}>
                <input
                  type="file"
                  id="pdfUpload"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />

                <label
                  htmlFor="pdfUpload"
                  style={{
                    padding: "9px 5px",
                    backgroundColor: "#C11F3B",
                    color: "#fff",
                    borderRadius: "5px",
                    fontSize: "14px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {handoverGuidelineFile ? "Replace File" : "Click to Upload"}
                </label>
                {handoverGuidelineFile && (
                  <button
                    onClick={handleViewPdf}
                    style={{
                      backgroundColor: "#C11F3B",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      padding: "9px 20px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                      fontWeight: "bold"
                    }}
                  >
                    📄 View PDF
                  </button>
                )}
              </div>


              <div style={{ display: "flex", alignItems: "center", gap: "5px", marginBottom: "15px" }}>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#333",
                  }}
                >
                  {selectedFile ? (selectedFile.length <= 20 ? selectedFile : selectedFile.slice(0, 20) + "...") : ""}
                </div>
                {selectedFile && (

                  <div >
                    <button
                      onClick={handleUpload}
                      style={{
                        backgroundColor: "#C11F3B",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        padding: "5px 12px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                        fontWeight: "bold"
                      }}
                    >
                      Upload Document
                    </button>
                  </div>

                )}
              </div>


              {/* Export & Other Buttons */}
              <div style={{ display: "flex", gap: "15px" }}>
                <button
                  className="download_btn comman_btn"
                  onClick={(e) => assignHandoverDownloadHandler()}
                >
                  Export Possession CSV
                </button>

                {csv.length > 0 && (
                  <CSVLink
                    className="comman_btn w-0"
                    data={csv}
                    filename="assignedpossession.csv"
                    ref={csvInstance}
                  >
                    Export Possession CSV
                  </CSVLink>
                )}

                <Button
                  buttonClassName="comman_btn"
                  onClick={isOpen}
                  text="Assign Checklist"
                />
              </div>
            </div>
          </div>
          <div className="custom_data_table_content">
            {handoverGuidelineFile && assignHandoverChecklist && assignHandoverChecklist.length > 0 ? (
              <>
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      <th className="custom_data_table_heading">Tower</th>
                      <th className="custom_data_table_heading">
                        Property Number
                      </th>
                      <th className="custom_data_table_heading">
                        Contact Name
                      </th>
                      <th className="custom_data_table_heading">
                        Contact Number
                      </th>
                      <th className="custom_data_table_heading">Status</th>
                      <th className="custom_data_table_heading"></th>
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {assignHandoverChecklist?.map((item) => (
                      <tr className="custom_data_table_row" key={item.id}>
                        <td
                          className="custom_data_table_item"
                          style={{ textAlign: "center" }}
                        >
                          {item?.tower_name}
                        </td>
                        <td
                          className="custom_data_table_item"
                          style={{ textAlign: "center" }}
                        >
                          {item?.property_number}
                        </td>
                        <td
                          className="custom_data_table_item"
                          style={{ textAlign: "center" }}
                        >
                          {item?.user_name}
                        </td>
                        <td
                          className="custom_data_table_item"
                          style={{ textAlign: "center" }}
                        >
                          {item?.contact_number}
                        </td>
                        <td
                          className="custom_data_table_item"
                          style={{
                            textAlign: "center",
                            color:
                              item?.handover_status === "ACCEPTED"
                                ? "Green"
                                : item?.handover_status === "REJECTED"
                                  ? "Red"
                                  : "",
                          }}
                        >
                          {item?.handover_status ?? "PENDING"}
                        </td>
                        <td className="custom_data_table_item lead_btn_container table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <div style={{ display: "flex", gap: "15px" }}>
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() =>
                                  showModal(item.id, assignHandoverChecklist)
                                }
                              >
                                {viewIcon}
                              </button>
                              {show && (
                                <HandoverChecklistDetailModal
                                  show={show}
                                  handleClose={() => setShow(false)}
                                  handoverId={handoverId}
                                  handoverData={handoverData}
                                />
                              )}
                              {item?.handovers && item.handovers.length > 0 ? (
                                item?.handover_status?.toUpperCase() ===
                                  "ACCEPTED" ||
                                  item?.handover_status?.toUpperCase() ===
                                  "REJECTED" ? (
                                  <button
                                    className="custom_data_table_view_edit_item_btn"
                                    style={{
                                      opacity: "0.5",
                                      cursor: "not-allowed",
                                    }}
                                    disabled
                                  >
                                    {editIcon}
                                  </button>
                                ) : (
                                  <button
                                    className="custom_data_table_view_edit_item_btn"
                                    onClick={() => {
                                      isEditOpen();
                                      setHandoverData(item);
                                    }}
                                  >
                                    {editIcon}
                                  </button>
                                )
                              ) : (
                                <button
                                  className="custom_data_table_view_edit_item_btn"
                                  onClick={() => {
                                    isEditOpen();
                                    setHandoverData(item);
                                  }}
                                >
                                  {editIcon}
                                </button>
                              )}
                              <button
                                className="custom_data_table_view_edit_item_btn"
                                onClick={() => {
                                  isOpen();
                                  handleCopyHandoverChecklist(item);
                                }}
                              >
                                {copyIcon}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  totalRecords={totalCount}
                  onPageChange={onPageChange}
                  startValue={pageNum}
                />
              </>
            ) : (
              <>
                {
                  handoverGuidelineFile ? (
                    <div
                      style={{ textAlign: "center", padding: "20px", fontSize: "18px", fontWeight: "bold" }}
                    >
                      No assigned possessions found
                    </div>
                  ) : (
                    <div
                      style={{ textAlign: "center", padding: "20px", fontSize: "18px", fontWeight: "bold" }}
                    >
                      Upload possession guideline file
                    </div>
                  )
                }
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};
export default HandoverPropertyForm;
