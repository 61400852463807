/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getHandoverChecklist,
  handoverToCustomerProperty,
} from "../../store/slice/handoverChecklistSlice";
import { toast } from "react-toastify";
import Button from "../../component/Common/Button";
import CreatableSelect from "react-select/creatable";
import { getprojectId } from "../../store/slice/projectSlice";

const EditAssignedHandoverChecklist = ({ isEditOpen, handoverData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [handoverOptions, setHandoverOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const handoverchecklist = useSelector(
    (state) => state?.handoverChecklist?.handoverchecklist?.data
  );
  const { project } = useSelector((state) => state.project);
  const [handoverTitles, setHandoverTitles] = useState([]);

  const fetchHandoverChecklist = async (newPage) => {
    if (!hasMore || loading) return; // Stop if no more data or already loading
    setLoading(true);

    const response = await dispatch(
      getHandoverChecklist({ project_id: id, limit: 20, page: newPage })
    ).unwrap();

    if (response?.data.length > 0) {
      setHandoverOptions((prev) => [
        ...prev,
        ...response?.data
          ?.filter(
            (checklist) =>
              checklist?.handover_status === true &&
              checklist?.project_name === project?.name
          )
          ?.map((item) => ({
            value: String(item.id),
            label: item.title,
          })),
      ]);
    } else {
      setHasMore(false); // No more data available
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHandoverChecklist(1)
    dispatch(getprojectId(id));
    if (handoverData) {
      // Pre-fill handoverTitles with already assigned handover item titles
      setHandoverTitles(handoverData?.handovers?.map((item) => item.title));
    }
  }, [dispatch, handoverData]);

  const handleHandoverTitleChange = async (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "select_all")) {
      try {
        const response = await dispatch(getHandoverChecklist({ project_id: id })).unwrap();
        const handoverChecklistOptions = response?.data
          ?.filter(
            (checklist) =>
              checklist?.handover_status === true &&
              checklist?.project_name === project?.name
          )
          ?.map((item) => ({
            value: String(item.id),
            label: item.title,
          }));
        setHandoverOptions(handoverChecklistOptions);
        setHandoverTitles(handoverChecklistOptions?.map((option) => option.label));
      } catch (error) {
        console.log("Error Fetching possession checklist", error);

      }
    } else if (selectedOptions.some((option) => option.value === "deselect_all")) {
      setHandoverTitles([]);
    } else {
      setHandoverTitles(selectedOptions.map((option) => option.label));
    }
  };

  // const handleHandoverCheck = (event) => {
  //   const { value, checked } = event.target;
  //   setHandoverTitles((prev) =>
  //     checked ? [...prev, value] : prev.filter((item) => item !== value)
  //   );
  // };
  useEffect(() => {
    setData({
      handover_ids: handoverchecklist
        ?.filter((item) => handoverTitles?.includes(item.title))
        ?.map((item) => String(item.id)),
      customer_property_ids: [handoverData?.id],
      action: "edit",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handoverTitles, handoverchecklist]);

  const handleUpdateHandover = async (e) => {
    e.preventDefault();

    if (handoverTitles?.length > 0) {
      try {
        // Convert selected titles to their corresponding IDs
        await dispatch(
          handoverToCustomerProperty({
            id,
            data,
          })
        );
        toast.success("Possession Updated Successfully", {
          autoClose: 1500,
        });
        isEditOpen(); // Close the form
      } catch (error) {
        toast.error("Update Failed");
      }
    } else {
      toast.error("Please select at least one possession item");
    }
  };

  const handleScrollBottom = (e) => {
    if (hasMore && !loading && handoverchecklist.length === 20) {
      setPage((prev) => prev + 1);
      fetchHandoverChecklist(page + 1);
    }
  };

  return (
    <div className="project_edit_main_content center_table_body">
      <button
        className="comman_btn"
        style={{ marginBottom: "15px" }}
        onClick={isEditOpen}
      >
        Back to Possession
      </button>
      <div className="create_from_row">
        <h3>Edit Possession Items</h3>
        <div className="contact_property_main_wrapper">
          <div
            className="create_from_checkbox_content"
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              marginBottom: "20px",
            }}
          >
            <CreatableSelect
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={
                handoverOptions?.length === 0
                  ? "No Checklist Available"
                  : "Select Possession Items..."
              }
              isMulti
              options={
                handoverOptions?.length > 0 ? (handoverTitles.length !== handoverOptions.length
                  ? [
                    { value: "select_all", label: "Select All" },
                    ...handoverOptions,
                  ] : [{ value: "deselect_all", label: "Remove All" }])
                  : [{ value: "no_checklist", label: "No Checklist Available" }]
              }
              onChange={handleHandoverTitleChange}
              value={handoverOptions?.filter((option) =>
                handoverTitles?.includes(option.label)
              )}
              menuPortalTarget={document?.body}
              isDisabled={handoverOptions?.length === 0} // Disable select if no options
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
              onMenuScrollToBottom={handleScrollBottom}
            />
            {/* {handoverTitles.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  flexWrap: "wrap",
                }}  
              >
                <h4>Selected Handovers: </h4>
                {handoverTitles.map((title, index) => (
                  <div key={index}>{title},</div>
                ))}
              </div>
            )} */}
          </div>
          <hr />
        </div>

        <h4>Property: {handoverData?.property_number}</h4>

        <Button
          className="create_from_bottom_btn col_span_2"
          buttonClassName="comman_btn"
          onClick={handleUpdateHandover}
          text="Edit Possession"
        />
      </div>
    </div>
  );
};

export default EditAssignedHandoverChecklist;
