import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import leadReducer from "./slice/leadSlice";
import planReducer from "./slice/planSlice";
import projectReducer from "./slice/projectSlice";
import companyDetailSlice from "./slice/companyDetailSlice";
import inquirySlice from "./slice/inquirySlice";
import customerPropertyReducer from "./slice/customerPropertySlice";
import complaintSlice from "./slice/complaintSlice";
import noticeSlice from "./slice/noticeSlice";
import privilegeSlice from "./slice/privilegeSlice";
import dashboardSlice from "./slice/dashboardSlice";
import contactSlice from "./slice/contactSlice";
import mobileConfigSlice from "./slice/mobileConfigSlice";
import serviceSlice from "./slice/serviceSlice";
import handoverChecklistReducer from "./slice/handoverChecklistSlice";
const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    user: userReducer,
    company: companyDetailSlice,
    lead: leadReducer,
    plan: planReducer,
    handoverChecklist: handoverChecklistReducer,
    project: projectReducer,
    inquiry: inquirySlice,
    complaint: complaintSlice,
    customerProperty: customerPropertyReducer,
    notifications: noticeSlice,
    privilege: privilegeSlice,
    contact: contactSlice,
    service: serviceSlice,
    mobileConfig: mobileConfigSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
