/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { routes } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getHandoverChecklist,
  updateHandoverChecklist,
} from "../../store/slice/handoverChecklistSlice";
import Loader from "../../component/Common/Loader";
import Select from "react-select";
import { useForm } from "../../hooks/useForm";
import { copyIcon, editIcon } from "../../icons";
import { alpha, styled, Switch } from "@mui/material";
import Pagination from "../../component/Pagination/PaginationWithoutUrl";
import { toast } from "react-toastify";
import { red } from "@mui/material/colors";
import { getProjectList } from "../../store/slice/projectSlice";

const HandoverChecklist = () => {
  const { loading } = useSelector((state) => state.handoverChecklist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handoverchecklist = useSelector(
    (state) => state?.handoverChecklist?.handoverchecklist?.data
  );
  const totalCount = useSelector(
    (state) => state?.handoverChecklist?.handoverchecklist?.totalRecords
  );
  const value = location?.state?.value;
  const initialFValues = {
    project_id: value?.project_id ? value?.project_id : null,
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );
  const [handoverStatus, SetHandoverStatus] = useState({});
  const projectList = useSelector((state) => state.project.projects);

  const projectIdOptions = projectList?.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const projectOptions = projectIdOptions && [
    { value: "All Projects", label: "All Projects", id: "" },
    ...projectIdOptions,
  ];

  const [pageNum, setPageNum] = useState(0);

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: red[900],
      "&:hover": {
        backgroundColor: alpha(red[900], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: red[900],
    },
  }));

  useEffect(() => {
    setPageNum(0);
  }, [values?.project_id?.id]);

  useEffect(() => {
    const page = pageNum === 0 ? 1 : Math.floor(pageNum / 10) + 1;
    dispatch(
      getHandoverChecklist({
        page: page,
        limit: 10,
        project_id: values?.project_id?.id,
      })
    );
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch, pageNum, values?.project_id?.id]);


  // Initialize toggle states based on handover_status
  useEffect(() => {
    if (handoverchecklist) {
      const initialStates = {};
      handoverchecklist?.forEach((item) => {
        initialStates[item.id] = item.handover_status;
      });
      SetHandoverStatus(initialStates);
    }
  }, [handoverchecklist]);

  const onPageChange = (page) => {
    setPageNum(page);
  };

  const handleHandoverStatus = async (id) => {
    const newStatus = !handoverStatus[id];
    SetHandoverStatus((prev) => ({ ...prev, [id]: newStatus }));

    try {
      await dispatch(
        updateHandoverChecklist({ id, data: { handover_status: newStatus } })
      ).unwrap();
      toast.success("Possession Status Updated", {
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 1500,
      });
      console.error("Error updating status:", error);
      SetHandoverStatus((prev) => ({ ...prev, [id]: !newStatus }));
    }
  };

  const handleEditButton = (id) => {
    if (handoverStatus[id]) {
      navigate(`/possessionchecklist/edit/${id}`);
    } else {
      toast.info("Checklist is Disabled, Enable to edit", {
        autoClose: 1500,
      });
    }
  };

  const handleCopyButton = (id) => {
    if (handoverStatus[id]) {
      navigate(`/possessionchecklist/copy/${id}`);
    } else {
      toast.info("Checklist is Disabled, Enable to copy", {
        autoClose: 1500,
      });
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div className="project_edit_main_content">
        <div
          className="creat_edit_project_btn_row"
          style={{ justifyContent: "end" }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "220px" }}>
              <Select
                placeholder="Select Project..."
                value={values.project_id}
                onChange={(e) => handleInputChange(e, "project_id")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="project_id"
                id="project_id"
                options={projectOptions}
                menuPortalTarget={document?.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
            <Link
              to={routes.createHandoverChecklist}
              className="comman_btn ml-auto"
            >
              Add New Checklist
            </Link>
          </div>
        </div>
        <div className="col_lg_4 d_grid gap_15 top-margin">
          {handoverchecklist?.length > 0 ? (
            handoverchecklist?.map((item, index) => (
              <div key={index}>
                <div className="handoverchecklist_column">
                  <div className="handoverchecklist_title_name">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="handoverchecklist_title">
                        {item?.project_name}: {item?.title}
                      </p>
                      <div style={{ display: "flex", gap: "6px" }}>
                        <PinkSwitch
                          checked={handoverStatus[item.id] || false} // Check individual state
                          onChange={() => handleHandoverStatus(item.id)} // Toggle only this switch
                          className="react-switch"
                        />
                        <button
                          className="edit_btn"
                          style={{
                            opacity: !handoverStatus[item.id] ? 0.3 : 1,
                            cursor: !handoverStatus[item.id]
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={() => handleEditButton(item.id)}
                        >
                          {editIcon}
                        </button>
                        <button
                          className="edit_btn"
                          style={{
                            opacity: !handoverStatus[item.id] ? 0.3 : 1,
                            cursor: !handoverStatus[item.id]
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={() => handleCopyButton(item.id)}
                        >
                          {copyIcon}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="handoverchecklist_details"
                    style={{
                      opacity: handoverStatus[item?.id] ? 1 : 0.2, // Adjust opacity based on handover_status
                      transition: "opacity 0.3s ease-in-out",
                      userSelect: handoverStatus[item.id] ? "auto" : "none",
                    }}
                  >
                    <span className="handoverchecklist_verify_list">
                      Need To Verify
                    </span>
                    <div className="handoverchecklist_list">
                      <ul style={{ lineHeight: "2", fontWeight: "bold" }}>
                        {JSON.parse(item?.description)?.map((desc, index) =>
                          desc?.subItems && desc?.subItems.length > 0 ? (
                            <li key={index}>
                              {desc?.title}
                              <ol
                                type="i"
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "30px",
                                  lineHeight: "1.25",
                                  fontWeight: "normal",
                                }}
                              >
                                {desc?.subItems?.map((subitem, index) => (
                                  <li key={index}>{subitem}</li>
                                ))}
                              </ol>
                            </li>
                          ) : (
                            <li key={index}>{desc?.title}</li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                position: "absolute",
                left: "55%",
                transform: "translate(-50%, -50%)",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              {values?.project_id?.value &&
                values?.project_id.value !== "All Projects" ? (
                <>No Checklist found for {values?.project_id?.value}</>
              ) : (
                <>No Checklist found</>
              )}
            </div>
          )}
        </div>
        <Pagination
          totalRecords={totalCount}
          onPageChange={onPageChange}
          startValue={pageNum}
        />
      </div>
    </>
  );
};

export default HandoverChecklist;
