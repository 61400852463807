import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";

export const getHandoverChecklist = createAsyncThunk(
  "getHandoverChecklist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `/projects/get-handover-checklist/?page=${data.page ?? 1}${data.limit ? `&limit=${data.limit}` : ""}${data.project_id ? `&project_id=${data.project_id}` : ""}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalRecords: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("error fetching handoverchecklist", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addHandoverChecklist = createAsyncThunk(
  "addHandoverChecklist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        "/projects/add-handover-checklist",
        data,
        authHeaders()
      );
      console.log(response);
    } catch (error) {
      console.log("error adding handoverlist", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateHandoverChecklist = createAsyncThunk(
  "updateHandoverChecklist",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await Axios.put(
        `/projects/update-handover-checklist/${id}`,
        data,
        authHeaders()
      );
      console.log(response);
    } catch (error) {
      console.log("error adding handoverlist", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAssignHandoverChecklist = createAsyncThunk(
  "getAssignHandoverChecklist",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `/projects/assigned-handover/${data.id}?search=${data.search}&status=${data.status}&page=${data.page}&limit=${data.limit}`,
        authHeaders()
      );
      // console.log(response);
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("error adding handoverlist", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAssignHandoverChecklistForCSV = createAsyncThunk(
  "getAssignHandoverChecklistForCSV",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `/projects/assigned-handover/${data.id}?search=${data.search}&status=${data.status}`,
        authHeaders()
      );
      // console.log(response);
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("error adding handoverlist", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAssignHandoverById = createAsyncThunk(
  "getAssignHandoverById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `/projects/assigned-handover/${id}`,
        authHeaders()
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log("error adding handoverlist", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const handoverToCustomerProperty = createAsyncThunk(
  "handoverToCustomerProperty",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      await Axios.post(`/projects/assign-handover/${id}`, data, {
        ...authHeaders(),
      });
    } catch (error) {
      console.log("Error in handingOver to customer property", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  handoverchecklist: [],
  assignHandoverChecklist: [],
  assignedHandover: [],
  loading: false,
  error: null,
};

export const handoverChecklistSlice = createSlice({
  name: "handoverChecklist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHandoverChecklist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHandoverChecklist.fulfilled, (state, action) => {
        state.loading = false;
        state.handoverchecklist = action.payload;
      })
      .addCase(getHandoverChecklist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addHandoverChecklist.pending, (state) => {
        state.loading = true;
      })
      .addCase(addHandoverChecklist.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addHandoverChecklist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateHandoverChecklist.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHandoverChecklist.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateHandoverChecklist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAssignHandoverChecklist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssignHandoverChecklist.fulfilled, (state, action) => {
        state.loading = false;
        state.assignHandoverChecklist = action.payload;
      })
      .addCase(getAssignHandoverChecklist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAssignHandoverById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssignHandoverById.fulfilled, (state, action) => {
        state.loading = false;
        state.assignedHandover = action.payload;
      })
      .addCase(getAssignHandoverById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(handoverToCustomerProperty.pending, (state) => {
        state.loading = true;
      })
      .addCase(handoverToCustomerProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.assignHandoverChecklist = action.payload;
      })
      .addCase(handoverToCustomerProperty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default handoverChecklistSlice.reducer;
