import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import LeadItem from "../../component/Lead/LeadItem";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import "../../css/style.css";
import LeadDetailModal from "../../helpers/leads/leadDetailModal";
import LeadReferModal from "../../helpers/leads/leadReferModal";
import { useForm } from "../../hooks/useForm";
import { searchIcon } from "../../icons";
import { clearLead, getAllLead, getLead } from "../../store/slice/leadSlice";
import Select from "react-select";
import { CSVLink } from "react-csv";

const LeadPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const location = useLocation();
  const locationValues = location?.state?.locationValues;

  const initialFValues = {
    phone_number: locationValues?.phone_number
      ? locationValues?.phone_number
      : "",
    status: locationValues?.status ? locationValues?.status : "",
  };

  const leadStatusOptions = [
    { value: "", label: "All Status" },
    { value: "Submitted", label: "Submitted" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Visited", label: "Visited" },
    { value: "Converted", label: "Converted" },
    { value: "Rejected", label: "Rejected" },
  ];

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const [show, setShow] = useState(false);
  const [leadId, setLeadId] = useState("");

  const [showUser, setShowUser] = useState(false);
  const [refUserId, setRefUserId] = useState("");

  const [userType, setUserType] = useState("");

  const showModal = (i) => {
    setShow(true);
    setLeadId(i);
  };

  const showUserModal = (i, uType) => {
    setShowUser(true);
    setRefUserId(i);
    setUserType(uType);
  };

  const hideModal = () => {
    setShow(false);
  };

  const hideUserModal = () => {
    setShowUser(false);
  };

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.leads}/page/1`);
    setValues({ ...values, phone_number: e.target.value });
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    dispatch(
      getLead({
        start: (+page_number - 1) * 10,
        phoneNumber: e.target.value,
        status: values.status.value,
        limit: 10,
      })
    );
  };

  const leadList = useSelector((state) => state.lead.lead?.data);
  const totalCount = useSelector((state) => state.lead.lead?.totalCount);

  useEffect(() => {
    dispatch(clearLead());
    dispatch(
      getAllLead({
        phone_number: "",
        status: "",
      })
    );
  }, []);

  const allLead = useSelector((state) => state.lead.leads?.data);
  const [lead, setLead] = useState([]);
  const [csv, setCsv] = useState([]);

  useEffect(() => {
    const leadListOne = allLead?.map((item, i) => {
      return [
        item.first_name + item.last_name,
        item.project_name,
        item.status,
        item.referred_first_name + item.referred_last_name,
        item.user_type,
        item.phone_number,
        item.referred_phone_number,
        item.lead_email,
        item.remarks,
        item.reward_points,
        item.created_at,
      ];
    });
    setLead(leadListOne);
  }, [allLead]);

  useEffect(() => {
    const csvData =
      lead?.length > 0
        ? [
          [
            "Name",
            "Project Name",
            "Status",
            "Referred By",
            "Referred Type",
            "Phone Number",
            "Referred Phone Number",
            "Email",
            "Remarks",
            "Reward Points",
            "Created At",
          ],
          ...lead,
        ]
        : [];
    setCsv(csvData);
  }, [lead]);

  const loader = useSelector((state) => state.lead.loader);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.leads}/page/1`);
      dispatch(clearLead());
      dispatch(
        getLead({
          phoneNumber: values.phone_number,
          status: values.status.value,
        })
      );
    }
  }, [dispatch, values.status]);

  useEffect(() => {
    // if (!locationValues?.phone_number && !locationValues?.status) {
    navigate(`${routes.leads}/page/${page_number}`);
    dispatch(clearLead());
    // if (+page_number === 1)
    dispatch(
      getLead({
        start: (page_number - 1) * 10,
        phoneNumber: values.phone_number,
        status: values.status.value,
      })
    );
    ref.current = true;
    // } else {
    // }
  }, [dispatch]);

  const onPageChange = (page_number) => {
    dispatch(
      getLead({
        start: page_number,
        status: values.status.value,
        phoneNumber: values.phone_number,
        limit: 10,
      })
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content lead_page_body">
        <div className="creat_edit_project_btn_row">
          {/* <CSVDownload data={csvData} target="_blank" />; */}
          {csv.length > 0 && (
            <CSVLink className="comman_btn" data={csv}>
              Export CSV
            </CSVLink>
          )}

          <Link to={routes.createLead} className="comman_btn ml-auto">
            Create Lead
          </Link>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder="Search by Mobile-number"
              id="phone_number"
              name="phone_number"
              maxLength={10}
              value={values.phone_number}
              onChange={mobileUserHandler}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_select lead_status-options">
            <Select
              value={values.status}
              onChange={(e) => handleInputChange(e, "status")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="status"
              options={leadStatusOptions}
            />
          </div>
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Name</th>
                <th className="custom_data_table_heading">Project Name</th>
                <th className="custom_data_table_heading">Status</th>
                <th className="custom_data_table_heading">Referred By</th>
                <th className="custom_data_table_heading">Referred Type</th>
                <th className="custom_data_table_heading">Phone Number</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {leadList &&
                leadList.map((item, i) => {
                  return (
                    <LeadItem
                      key={i}
                      item={item}
                      i={i}
                      showModal={showModal}
                      showUserModal={showUserModal}
                      values={values}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
        <LeadDetailModal show={show} handleClose={hideModal} leadId={leadId}>
          <p>Modal</p>
        </LeadDetailModal>
        <LeadReferModal
          showUser={showUser}
          hideUserModal={hideUserModal}
          refUserId={refUserId}
          userType={userType}
        >
          <p>Modal</p>
        </LeadReferModal>
      </div>
    </>
  );
};

export default LeadPage;
