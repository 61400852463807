import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  createComplaintReply,
  editComplaintReply,
  getComplaintDetailByComplaintId,
} from "../../store/slice/complaintSlice";
import Loader from "../../component/Common/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { leftArrow, rightArrow } from "../../icons";
import { computeHeadingLevel } from "@testing-library/react";

const ComplaintDetailModal = ({ handleClose, show, complaintId }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const dispatch = useDispatch();
  const [nav1, setNav1] = useState(null);
  const [mouseDownX, setMouseDownX] = useState(0);
  const loader = useSelector((state) => state.complaint.loader);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [openChat, setOpenChat] = useState(false);
  const [message, setMessage] = useState("");
  const [editCompId, setEditCompId] = useState("");

  useEffect(() => {
    if (complaintId) {
      dispatch(getComplaintDetailByComplaintId(complaintId));
    }
  }, [complaintId, dispatch]);

  const complaintDetailById = useSelector(
    (state) => state.complaint.complaintDetailId
  );

  const parseRejectionAttachment = (data) => {
    if (!data) return [];
    try {
      const parsedData = JSON.parse(data);
      return Array.isArray(parsedData) ? parsedData : [parsedData];
    } catch (error) {
      return [data];
    }
  };

  const rejection_attachment = parseRejectionAttachment(complaintDetailById?.rejection_attachment)
  const attachment = parseRejectionAttachment(complaintDetailById?.attachment)

  const chatHandler = () => {
    setOpenChat(!openChat);
  };

  const messageOnchange = (e) => {
    setMessage(e.target.value);
  };

  const editMessageChange = (id, message) => {
    setMessage(message);
    setEditCompId(id);
  };

  const sendMessageHandler = (e) => {
    e.preventDefault();
    const data = {
      complaint_id: complaintId,
      message: message,
      sender: "1",
    };
    !editCompId
      ? dispatch(
        createComplaintReply({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(getComplaintDetailByComplaintId(complaintId));
              setMessage("");
            }
          },
        })
      )
      : dispatch(
        editComplaintReply({
          data: { message },
          id: editCompId,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(getComplaintDetailByComplaintId(complaintId));
              setMessage("");
            }
          },
        })
      );
  };
  const handleWheelZoom = (event) => {
    if (event.deltaY < 0) {
      setZoom((prev) => Math.min(prev + 0.2, 3));
    } else {
      setZoom((prev) => Math.max(prev - 0.2, 1));
    }
  };
  return (
    <>
      {loader && <Loader />}
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_video_img">
              <p className="inquiry_complaint_detail_status">
                {complaintDetailById?.status}
              </p>
              {
                attachment.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    {attachment.length > 1 && (
                      <button
                        onClick={() => nav1?.slickPrev()}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "10px",
                          transform: "translateY(-50%)",
                          zIndex: 10,
                          background: "rgba(0,0,0,0.5)",
                          color: "#fff",
                          border: "none",
                          padding: "10px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                      >
                        {leftArrow}
                      </button>
                    )}

                    <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
                      {attachment?.map((file, index) => (
                        <div className="plan_slider_box" key={index}>
                          {/\.(mp4|webm|ogg)$/i.test(file) ? (
                            <video
                              controls
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "500px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              className="plan_slider_box_img"
                              onClick={() =>
                                setFullscreenImage(
                                  `${process.env.REACT_APP_FILE_BASE_URL}/${file}`
                                )
                              }
                            >
                              <source
                                src={`${process.env.REACT_APP_FILE_BASE_URL}/${file}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_FILE_BASE_URL}/${file}`}
                              alt="Complaint item"
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "500px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              className="plan_slider_box_img"
                              onClick={() =>
                                setFullscreenImage(
                                  `${process.env.REACT_APP_FILE_BASE_URL}/${file}`
                                )
                              }
                            />
                          )}
                        </div>
                      ))}
                    </Slider>

                    {attachment.length > 1 && (
                      <button
                        onClick={() => nav1?.slickNext()}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          zIndex: 10,
                          background: "rgba(0,0,0,0.5)",
                          color: "#fff",
                          border: "none",
                          padding: "10px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                      >
                        {rightArrow}
                      </button>
                    )}
                  </div>
                ) : rejection_attachment.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    {rejection_attachment.length > 1 && (
                      <button
                        onClick={() => nav1?.slickPrev()}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "10px",
                          transform: "translateY(-50%)",
                          zIndex: 10,
                          background: "rgba(0,0,0,0.5)",
                          color: "#fff",
                          border: "none",
                          padding: "10px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                      >
                        {leftArrow}
                      </button>
                    )}

                    <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
                      {rejection_attachment?.map((file, index) => (
                        <div className="plan_slider_box" key={index}>
                          {/\.(mp4|webm|ogg)$/i.test(file) ? (
                            <video
                              controls
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "500px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              className="plan_slider_box_img"
                              onClick={() =>
                                setFullscreenImage(
                                  `${process.env.REACT_APP_FILE_BASE_URL}/${file}`
                                )
                              }
                            >
                              <source
                                src={`${process.env.REACT_APP_FILE_BASE_URL}/${file}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_FILE_BASE_URL}/${file}`}
                              alt="Rejection item"
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "500px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              className="plan_slider_box_img"
                              onClick={() =>
                                setFullscreenImage(
                                  `${process.env.REACT_APP_FILE_BASE_URL}/${file}`
                                )
                              }
                            />
                          )}
                        </div>
                      ))}
                    </Slider>

                    {rejection_attachment.length > 1 && (
                      <button
                        onClick={() => nav1?.slickNext()}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          zIndex: 10,
                          background: "rgba(0,0,0,0.5)",
                          color: "#fff",
                          border: "none",
                          padding: "10px 15px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                      >
                        {rightArrow}
                      </button>
                    )}
                  </div>
                ) : (
                  <img
                    src="https://www.medstartr.com/main/images/no-image.png"
                    alt="default pic"
                    className="default_image"
                  />
                )
              }
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_create_updated_date">
                <div className="inquiry_complaint_detail_create_date">
                  <h2>Created At </h2>
                  <p>
                    {moment(complaintDetailById?.created_at).format(
                      "DD/MM/YYYY, h:mm A"
                    )}
                  </p>
                </div>
                {complaintDetailById?.updated_at && (
                  <div className="inquiry_complaint_detail_updated_date">
                    <h2>Updated At </h2>
                    <p>
                      {moment(complaintDetailById?.updated_at).format(
                        "DD/MM/YYYY, h:mm A"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="inquiry_complaint_detail_text text_right">
                <button
                  onClick={chatHandler}
                  className="inquiry_complaint_detail_heading chat_btn"
                >
                  {!openChat ? "Open Chat" : "Close Chat"}
                </button>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Description
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {complaintDetailById?.description}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">Subject</h2>
                <p className="inquiry_complaint_detail_name">
                  {complaintDetailById?.subject}
                </p>
              </div>
              {complaintDetailById?.remarks && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Remarks</h2>
                  <p className="inquiry_complaint_detail_name">
                    {complaintDetailById?.remarks}
                  </p>
                </div>
              )}
              {complaintDetailById?.review && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Review</h2>
                  <p className="inquiry_complaint_detail_name">
                    {complaintDetailById?.review}
                  </p>
                </div>
              )}
              {complaintDetailById?.rating && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Rating</h2>
                  <p className="inquiry_complaint_detail_name">
                    {complaintDetailById?.rating}
                  </p>
                </div>
              )}
            </div>
          </div>
          {openChat && (
            <>
              <main className="msger-chat">
                {complaintDetailById?.replies?.map((item) => {
                  if (item.sender !== 1) {
                    return (
                      <div className="msg left-msg">
                        <div className="msg-bubble">
                          <div className="msg-text">{item.message}</div>
                          <div className="msg-info-time">
                            {moment(item?.created_at).format(
                              "DD/MM/YYYY, h:mm A"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="msg right-msg">
                        <div className="msg-bubble">
                          <div className="msg-text">{item.message}</div>
                          <div className="msg-info-time">
                            {moment(item?.created_at).format(
                              "DD/MM/YYYY, h:mm A"
                            )}
                          </div>
                        </div>
                        <button
                          type="button"
                          className="right-msg-more-btn"
                          onClick={() =>
                            editMessageChange(item.id, item.message)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  }
                })}
              </main>
              <form className="msger-inputarea">
                <input
                  type="text"
                  className="msger-input"
                  placeholder="Enter your message..."
                  name="msg"
                  value={message}
                  onChange={(e) => messageOnchange(e)}
                />
                <button
                  disabled={!message}
                  onClick={sendMessageHandler}
                  className="msger-send-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                    />
                  </svg>
                </button>
              </form>
            </>
          )}
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            Close
          </button>
        </div>
      </section>
      {fullscreenImage && (
        <div
          className="fullscreen-modal"
          onWheel={handleWheelZoom}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 999999,
          }}
        >
          {/\.mp4|\.webm|\.ogg$/i.test(fullscreenImage) ? (
            <video
              controls
              autoPlay
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
              }}
            >
              <source src={fullscreenImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={fullscreenImage}
              alt="fullscreen"
              style={{
                transform: `scale(${zoom})`,
                transition: "transform 0.2s ease-in-out",
                maxWidth: "90%",
                maxHeight: "90%",
              }}
            />
          )}

          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <button
              onClick={() => setFullscreenImage(null)}
              style={{
                padding: "10px",
                backgroundColor: "#fff",
                color: "#333",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ComplaintDetailModal;
