export const routes = {
  homepage: "/",
  all: "*",
  login: "/login",
  verifyOtp: "/verifyotp",

  dashboard: "/dashboard",
  dashboardRewardPoints: "/dashboard/rewardpoints",

  projects: "/projects",
  featuredProjects: "/featuredProjects",
  projectsByPage: "/projects",
  createProject: "/projects/create",
  editProject: "/projects/edit/:id",

  rewardHistory: "/reward-history",
  rewardHistoryByPage: "/reward-history/page/:page_number",

  leads: "/leads",
  leadsByPage: "/leads/page/:page_number",
  createLead: "/leads/create",
  viewLead: "/leads/:id",
  editLead: "/leads/edit/:id/:page_number",

  plans: "/plans",
  plansByPage: "/plans/page/:page_number",
  createPlan: "/plans/create",
  viewPlan: "/plans/:id/:page_number",
  editPlan: "/plans/edit/:id/:page_number",

  createCustomerProperty: "/properties/customer/create",
  createBulkProperties: "/properties/bulk/create",
  customerProperty: "/properties/customer",
  customerPropertyByPage: "/properties/customer/page/:page_number",
  editCustomerProperty: "/properties/customer/edit/:id/:page_number",
  importContactsCustomerProperty:
    "/properties/customer/edit/:id/assigncontact/:page_number",

  users: "/users",
  usersByPage: "/users/page/:page_number",
  userDetail: "/users/detail/:id/:page_number",

  inquiries: "/inquiries",
  inquiriesByPage: "/inquiries/page/:page_number",
  editInquiry: "/inquiries/edit/:id/:page_number",
  viewInquiry: "/inquiries/:id/:page_number",

  complaints: "/complaints",
  complaintsByPage: "/complaints/page/:page_number",
  editComplaint: "/complaints/edit/:id/:page_number",

  company: "/company",
  createCompany: "/company/create",
  editCompany: "/company/edit",

  notifications: "/notifications",
  notificationsByPage: "/notifications/page/:page_number",
  createNotice: "/notifications/create",
  // editNotice: "/notifications/edit/:id",

  privileges: "/privileges",
  privilegesByPage: "/privileges/page/:page_number",
  createPrivilege: "/privileges/create",
  editPrivilege: "/privileges/edit/:id/:page_number",

  service: "/service",
  serviceByPage: "/service/page/:page_number",
  createService: "/service/create",
  editService: "/service/edit/:id",

  handoverChecklist: "/possessionchecklist",
  createHandoverChecklist: "/possessionchecklist/create",
  editHandoverChecklist: "/possessionchecklist/edit/:id",
  copyHandoverChecklist: "/possessionchecklist/copy/:id",

  contacts: "/contacts",
  contactsByPage: "/contacts/page/:page_number",
  createContact: "/contacts/create",
  editContact: "/contacts/edit/:id/:page_number",

  feedBack: "/feedbacks",
  editFeedBack: "/feedbacks/edit/:id",
  feedBackBypage: "/feedbacks/page/:page_number",

  mobileConfiguration: "/mobileConfiguration",
};
