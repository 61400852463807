import React, { useState } from "react";
import { downArrow, leftArrow, rightArrow, upArrow, viewIcon } from "../../icons";
import { useNavigate } from "react-router-dom";

const HandoverChecklistDetailModal = ({
  handleClose,
  show,
  handoverId,
  handoverData,
}) => {
  const showHideClassName = show ? "user_detail_modal_show" : "";
  const handoverItem = handoverData?.filter((item) => item.id === handoverId);
  const [expanded, setExpanded] = useState({});
  const [fullscreenImages, setFullscreenImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [zoom, setZoom] = useState(1);
  const navigate = useNavigate();

  const toggleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleWheelZoom = (event) => {
    setZoom((prev) => (event.deltaY < 0 ? Math.min(prev + 0.2, 3) : Math.max(prev - 0.2, 1)));
  };

  const openImageViewer = (images) => {
    if (images?.length > 0) {
      setFullscreenImages(images);
      setCurrentImageIndex(0); // Start from the first image
    }
  };

  const handleNextImage = (e) => {
    e.preventDefault()
    setCurrentImageIndex((prev) => (prev + 1) % fullscreenImages.length);
  };

  const handlePrevImage = (e) => {
    e.preventDefault()
    setCurrentImageIndex((prev) => (prev - 1 + fullscreenImages.length) % fullscreenImages.length);
  };

  const isVideo = (file) => {
    return file?.toLowerCase().endsWith(".mp4");
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section
        className={`user_detail_modal_main ${showHideClassName}`}
        style={{
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body">
              <h2
                style={{
                  marginBottom: "5px",
                  borderBottom: "2px solid #ddd",
                  paddingBottom: "5px",
                }}
              >
                Possession Details
              </h2>
              {handoverItem?.map((item, index) => (
                <div key={index}>
                  <div
                    className="inquiry_complaint_detail_text"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                      padding: "10px 0",
                    }}
                  >
                    <div style={{ display: "flex", gap: "35px" }}>
                      <div>
                        <h2 className="inquiry_complaint_detail_heading">
                          Contact Number
                        </h2>
                        <p
                          className="inquiry_complaint_detail_name"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          {item?.contact_number}
                        </p>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h2 className="inquiry_complaint_detail_heading">
                          Witness Contact
                        </h2>
                        <p
                          className="inquiry_complaint_detail_name"
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          {item?.handover_wtname} {item?.handover_wtnumber}

                        </p>
                      </div>

                    </div>
                    {item?.handover_signature ? (
                      <>
                        <div>
                          <h2 className="inquiry_complaint_detail_heading">
                            Signature
                          </h2>
                          <p className="inquiry_complaint_detail_name">
                            <img
                              src={`${process.env.REACT_APP_FILE_BASE_URL}/${item?.handover_signature}`}
                              alt="signature"
                              width={200}
                              height={80}
                            />
                          </p>
                        </div>
                      </>
                    ) : (
                      <div>
                        <h2 className="inquiry_complaint_detail_heading">
                          Signature
                        </h2>
                        <p className="inquiry_complaint_detail_name">
                          Not Available
                        </p>
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "end", gap: "5px", overflowWrap: "anywhere", flexWrap: "wrap", marginBottom: "10px" }}>
                    <p className="inquiry_complaint_detail_heading">
                      Remark {item?.handover_remark ? <p style={{ color: "black", fontSize: "16px", fontWeight: "normal" }}>{item?.handover_remark}</p> : <p style={{ color: "black", fontSize: "16px", fontWeight: "normal" }}>No Remark</p>}
                    </p>
                    {item?.hand_remark_images && JSON.parse(item?.hand_remark_images).length > 0 && (
                      <button className="custom_data_table_view_edit_item_btn" onClick={() => openImageViewer(JSON.parse(item?.hand_remark_images))}>
                        {viewIcon}
                      </button>
                    )}
                  </div>

                  <h2
                    style={{
                      marginBottom: "5px",
                      borderBottom: "2px solid #ddd",
                      paddingBottom: "5px",
                    }}
                  >
                    Checklist Details
                  </h2>
                  <div>
                    {item?.handovers ? (
                      item?.handovers?.map((handoveritems, index) => (
                        <div
                          key={index}
                          className="inquiry_complaint_detail_text handover-item"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor:
                              handoveritems?.status.toLowerCase() === "rejected"
                                ? "#ffdddd"
                                : "#f9f9f9",
                            padding: "15px",
                            borderRadius: "8px",
                            marginBottom: "10px",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                            transition: "all 0.3s ease-in-out",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: "pointer",
                              fontWeight: "600",
                            }}
                            onClick={() => toggleExpand(index)}
                          >
                            <h3 className="inquiry_complaint_detail_heading">
                              {handoveritems?.title}
                            </h3>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    handoveritems?.status.toLowerCase() ===
                                      "rejected"
                                      ? "#c11f3b"
                                      : "#5cb85c",
                                }}
                              >
                                {handoveritems?.status}
                              </span>
                              {expanded[index] ? upArrow : downArrow}
                            </div>
                          </div>
                          {expanded[index] && (
                            <div
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                gap: "20px",
                                justifyContent: "space-between",
                                transition: "max-height 0.3s ease-in-out",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <h4 className="inquiry_complaint_detail_heading">
                                  Description
                                </h4>
                                <ul
                                  className="inquiry_complaint_detail_name"
                                  style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {JSON.parse(handoveritems?.description).map(
                                    (desc, index) =>
                                      desc?.subItems &&
                                        desc?.subItems.length > 0 ? (
                                        <li key={index}>
                                          {desc?.title}
                                          <ol
                                            type="i"
                                            style={{
                                              marginTop: "0px",
                                              marginLeft: "30px",
                                              lineHeight: "1.25",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {desc?.subItems?.map(
                                              (subitem, index) => (
                                                <li key={index}>{subitem}</li>
                                              )
                                            )}
                                          </ol>
                                        </li>
                                      ) : (
                                        <li key={index}>{desc?.title}</li>
                                      )
                                  )}
                                </ul>
                              </div>
                              <div style={{ width: "100%" }}>
                                {handoveritems?.status.toLowerCase() ===
                                  "rejected" && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      <div>
                                        <h4 className="inquiry_complaint_detail_heading">
                                          Reason
                                        </h4>
                                        <p
                                          className="inquiry_complaint_detail_name"
                                          style={{
                                            textAlign: "start",
                                          }}
                                        >
                                          {handoveritems?.rejected_reason}
                                        </p>
                                      </div>

                                      {handoveritems?.rejected_attachment && (
                                        <div>
                                          <h4 className="inquiry_complaint_detail_heading" onClick={() => navigate(`/complaints/page/${1}`)}>
                                            View Attachment
                                          </h4>
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        No Possession Data found
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
            style={{
              padding: "10px 20px",
              backgroundColor: "#C11F3B",
              color: "#fff",
              borderRadius: "5px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Close
          </button>
        </div>
      </section>

      {fullscreenImages.length > 0 && (
        <div
          className="fullscreen-modal"
          onWheel={handleWheelZoom}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 999999,
          }}
        >
          <button className="nav-button left" style={{
            position: "absolute",
            left: "100px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "rgba(255, 255, 255, 0.5)",
            border: "none",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            zIndex: "1",
            fontSize: "24px",
            color: "#333",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          }}
            onClick={(e) => handlePrevImage(e)}>{leftArrow}</button>

          {isVideo(fullscreenImages[currentImageIndex]) ? (
            <video
              controls
              style={{ maxWidth: "90%", maxHeight: "90%" }}
            >
              <source src={`${process.env.REACT_APP_FILE_BASE_URL}/${fullscreenImages[currentImageIndex]}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={`${process.env.REACT_APP_FILE_BASE_URL}/${fullscreenImages[currentImageIndex]}`}
              alt="Fullscreen Preview"
              style={{
                transform: `scale(${zoom})`,
                transition: "transform 0.2s ease-in-out",
                maxWidth: "90%",
                maxHeight: "90%",
              }}
            />
          )}

          <button className="nav-button right" style={{
            position: "absolute",
            right: "100px",
            top: "50%",
            transform: "translateY(-50%)",
            background: "rgba(255, 255, 255, 0.5)",
            border: "none",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "24px",
            color: "#333",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          }}
            onClick={(e) => handleNextImage(e)}>{rightArrow}</button>

          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <button
              onClick={() => setFullscreenImages([])}
              style={{
                padding: "10px",
                backgroundColor: "#fff",
                color: "#333",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HandoverChecklistDetailModal;
