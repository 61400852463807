import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { createContact, updateContact } from "../../store/slice/contactSlice";
import { getServiceList } from "../../store/slice/serviceSlice";
import CreatableSelect from "react-select/creatable";
import TextArea from "../../component/Common/TextArea";
import { imageProps } from "../../props";
import { fileUpload } from "../../helpers/fileUpload";
import { getProjectList } from "../../store/slice/projectSlice";
import { addIcon, removeIcon } from "../../icons";

const CreateContactPage = ({ contactDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id, page_number } = params;

  const [uploading, setUploading] = useState(false);
  const [duplicateProjectIdError, setDuplicateProjectIdError] = useState("");

  const serviceList = useSelector((state) => state?.service?.serviceList?.data);
  const projectList = useSelector((state) => state?.project?.projects);
  const loader = useSelector((state) => state?.contact?.loader);

  const serviceLoader = useSelector((state) => state?.service?.loader);
  const projectLoader = useSelector((state) => state?.project?.loader);

  const parsedTowerList =
    projectList &&
    projectList?.map((item) => ({
      id: item?.id,
      tower_names: item?.tower_names ? JSON?.parse(item?.tower_names) : [],
    }));

  useEffect(() => {
    dispatch(getServiceList({ start: 0, limit: 10000 }));
    dispatch(getProjectList({ admin: "true" }));
  }, []);

  let serviceDropdown = serviceList?.map((item) => {
    return { id: item?.id, label: item?.service_name, value: item?.id };
  });

  let projectOptions = projectList?.map((item) => {
    return { value: item?.name, label: item?.name, id: item?.id };
  });
  const [contactProperties, setContactProperties] = useState([])

  const parsedContactProperties = useMemo(() => {
    return contactDetails?.contact_properties
      ? JSON?.parse(contactDetails?.contact_properties)
      : [];
  }, [contactDetails]);

  const parsedTowerLists = useMemo(() => {
    return projectList?.map((item) => ({
      id: item?.id,
      tower_names: item?.tower_names && JSON.parse(item?.tower_names),
    }));
  }, [projectList]);

  function updateContactProperty(parsedTowerList, parsedContactProperties) {
    return parsedContactProperties.map(contactProperty => {
      const parsedTower = parsedTowerList.find(tower => tower.id === contactProperty.project_id);

      if (!parsedTower) {
        return contactProperty;
      }

      let towerList = contactProperty?.towerList || [];
      let towerProperties = contactProperty?.tower_properties || [];

      // Create a map of existing towers with their isChecked values
      let towerMap = new Map();
      towerList.forEach(tower => {
        towerMap.set(tower.name, tower.isChecked);
      });

      let updatedTowerList = parsedTower.tower_names.map(tower => ({
        name: tower.name,
        property: tower.property,
        isChecked: towerMap.has(tower.name) ? towerMap.get(tower.name) : false
      }));

      let updatedTowerProperties = updatedTowerList?.map(tower => {
        let towerProperty = towerProperties.find(towerProp => towerProp.tower === tower.name);
        if (!towerProperty) {
          return { tower: tower.name, properties: [] };
        } else {
          return towerProperty
        }
      })

      return {
        ...contactProperty,
        towerList: updatedTowerList,
        tower_properties: updatedTowerProperties
      };
    });
  }

  useEffect(() => {
    const updateProperty = updateContactProperty(parsedTowerLists, parsedContactProperties);
    setContactProperties(
      updateProperty.length > 0
        ? updateProperty.map((contact) => ({
          project_id: {
            value: contact.project_id,
            label:
              contact.project_name === ""
                ? "Select Project..."
                : contact.project_name,
            id: contact.project_id,
          },
          project_name: contact.project_name,
          towerList: contact.towerList || [],
          towerProperties:
            contact.tower_properties?.map((tower) => ({
              tower: tower?.tower,
              properties: tower?.properties || [],
            })) || [],
        }))
        : [
          {
            project_id: null,
            project_name: "",
            towerList: [],
            towerProperties: [{ tower: "", properties: [] }],
          },
        ]
    )
  }, [parsedContactProperties, parsedTowerLists]);

  const checkDuplicateProjectId = (projectId) => {
    const existingIds = contactProperties?.map((item) => item.project_id?.id);
    return existingIds?.includes(projectId);
  };

  // console.log(
  //   "Editcontact123456",
  //   contactDetails?.contact_properties &&
  //     JSON.parse(contactDetails?.contact_properties)
  // );

  let initialCertificatePreview;
  if (contactDetails?.contact_certificate) {
    initialCertificatePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${contactDetails?.contact_certificate}`;
  }

  // console.log("Editcontact", contactDetails);

  const initialFValues = {
    company_name: contactDetails?.company_name
      ? contactDetails?.company_name
      : "",
    contact_name: contactDetails?.contact_name
      ? contactDetails?.contact_name
      : "",

    phone_number: contactDetails?.phone_number
      ? contactDetails?.phone_number
      : "",
    service_id: contactDetails?.service_id
      ? {
        value: contactDetails?.service_id,
        id: contactDetails?.service_id,
        label: contactDetails?.service_name,
      }
      : null,
    contact_address: contactDetails?.contact_address
      ? contactDetails?.contact_address
      : "",
    contact_certificate: contactDetails?.contact_certificate
      ? contactDetails?.contact_certificate
      : "",
    contact_certificate_preview:
      contactDetails?.contact_certificate &&
        !initialCertificatePreview.includes(".pdf")
        ? initialCertificatePreview
        : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("company_name" in fieldValues) {
      temp.company_name = fieldValues.company_name
        ? ""
        : "Company Name is required !";
    }
    if ("contact_name" in fieldValues) {
      temp.contact_name = fieldValues.contact_name ? "" : "Name is required !";
    }
    if ("phone_number" in fieldValues) {
      if (!fieldValues.phone_number) {
        temp.phone_number = "Phone number is required !";
      } else {
        temp.phone_number =
          fieldValues.phone_number.length === 10
            ? ""
            : "Phone number is not valid !";
      }
    }
    if ("service_id" in fieldValues) {
      temp.service_id = fieldValues?.service_id
        ? ""
        : "Project Id is required !";
    }
    if ("contact_address" in fieldValues) {
      temp.contact_address = fieldValues?.contact_address
        ? ""
        : "Address is required";
    }
    if ("contact_certificate" in fieldValues) {
      temp.contact_certificate = "";
      if (!fieldValues.contact_certificate) {
        temp.contact_certificate = "Certificate is required !";
      }

      if (fieldValues.contact_certificate.size > 5000000) {
        temp.contact_certificate = "File size should be less than 5Mb !";
        setValues({
          ...values,
          contact_certificate: "",
          contact_certificate_preview: "",
        });
      }
      if (
        fieldValues.contact_certificate.name &&
        !fieldValues.contact_certificate.name?.match(
          /.(jpg|jpeg|png|gif|pdf)$/i
        )
      ) {
        temp.contact_certificate = "Please select a valid File !";
        setValues({
          ...values,
          contact_certificate: "",
          contact_certificate_preview: "",
        });
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      contact_certificate: "",
      contact_certificate_preview: "",
    });
  };

  const onProjectChange = async (type, e, i, l) => {
    if (type === "project_id") {
      //Comparing the projectId with the existing Id present In an Array
      if (checkDuplicateProjectId(e?.id)) {
        setDuplicateProjectIdError(
          "Project is already assigned to this contact"
        );
        return;
      }
      setDuplicateProjectIdError("");

      let projectList = [...contactProperties];
      projectList[i].project_id = e;
      projectList[i].project_name = e?.label;
      setContactProperties(projectList);

      //Getting id to display a towerlist
      const selectedId = e ? e?.id : null;
      const selectedProject =
        parsedTowerList &&
        parsedTowerList?.find((item) => item?.id === selectedId);

      // Extracting id from the top level
      const projectId = selectedProject?.id;

      const finalTowerNames =
        selectedProject?.tower_names?.map((tower) => ({
          name: tower?.name,
          property: tower?.property,
          id: projectId !== undefined ? projectId : null,
          isChecked: false,
        })) ?? [];

      if (projectList[i] && projectList[i]?.towerList) {
        projectList[i].towerList = finalTowerNames;

        // Setting tower name in tower properties array
        projectList[i].towerProperties = finalTowerNames?.map((tower) => ({
          tower: tower?.name,
          properties: [],
        }));
        setContactProperties(projectList);
      }
    } else if (type === "properties") {
      // Update the towerProperties array based on the selected properties
      let projectList = [...contactProperties];
      if (
        projectList[i] &&
        projectList[i]?.towerProperties &&
        projectList[i]?.towerProperties[l]
      ) {
        projectList[i].towerProperties[l].properties =
          e?.map((property) => ({
            label: property?.value,
            value: property?.value,
          })) || [];
      }
      setContactProperties(projectList);
    }
  };

  const onCheckboxChange = (e, objIndex, itemIndex) => {
    let check = e?.target?.checked;
    let list = [...contactProperties];
    if (
      list[objIndex] &&
      list[objIndex]?.towerList &&
      list[objIndex]?.towerList[itemIndex]
    ) {
      list[objIndex].towerList[itemIndex].isChecked = check;
      //New added
      // Find the corresponding tower object in towerProperties array
      const towerName = list[objIndex]?.towerList[itemIndex]?.name;
      const towerPropertiesIndex = list[objIndex]?.towerProperties?.findIndex(
        (towerProp) => towerProp?.tower === towerName
      );
      // Update isChecked in towerProperties array
      if (towerPropertiesIndex !== -1) {
        list[objIndex].towerProperties[towerPropertiesIndex].isChecked = check;
      } else {
        // list[objIndex].towerProperties.push({ tower: towerName, properties: [], isChecked: check })
      }
      if (
        list[objIndex]?.towerProperties &&
        list[objIndex]?.towerProperties[towerPropertiesIndex]
      ) {
        // Clear properties array if checkbox is unchecked
        if (!check) {
          list[objIndex].towerProperties[towerPropertiesIndex].properties = [];
        }
      }
    }
    setContactProperties(list);
  };

  //Add Multiple property in contact
  const contactsPropertyAddClick = (i) => {
    let newObj = {
      project_id: null,
      project_name: "",
      towerList: [],
      towerProperties: [{ tower: "", properties: [] }],
    };
    setContactProperties((prevVal) => [...prevVal, newObj]);
  };

  //Remove Multiple property in contact
  const contactsPropertyRemoveClick = (i) => {
    setDuplicateProjectIdError("");
    const list = [...contactProperties];
    list?.splice(i, 1);
    setContactProperties(list);
  };

  //Get the list of properties option
  const getPropertiesOption = (tower) => {
    return tower?.property?.map((propertyItem) => ({
      value: propertyItem,
      label: propertyItem,
    }));
  };

  //Extract Values function to send in Api response
  const extractValuesToSendInApi = (val) => {
    let finalArr = val?.map((item) => {
      return {
        project_id: item?.project_id?.id || null,
        project_name: item?.project_name,
        towerList: item?.towerList,
        tower_properties: item?.towerProperties,
      };
    });
    // console.log("finalArr", finalArr);
    return finalArr;
  };

  const createContactHandler = async (e) => {
    e.preventDefault();
    setUploading(true);

    const formData = new FormData();
    let imageUrl;
    if (
      values?.contact_certificate &&
      typeof values?.contact_certificate !== "string"
    ) {
      formData.append("file", values?.contact_certificate);
      if (validate()) {
        imageUrl = await fileUpload(formData);
      }
      formData.delete("file");
    } else {
      imageUrl = values?.contact_certificate;
    }

    if (validate()) {
      const data = {
        contact_name: values.contact_name.trim(),
        phone_number: values.phone_number.trim(),
        service_id: values.service_id.id,
        contact_address: values.contact_address,
        company_name: values.company_name,
        contact_certificate: imageUrl,
        contact_properties: extractValuesToSendInApi(contactProperties),
      };

      if (id) {
        dispatch(updateContact({ data: data, id: id, navigate, page_number }));
      } else {
        dispatch(createContact({ data, navigate }));
      }
    }
    setUploading(false);
  };

  return (
    <>
      {(uploading || loader || serviceLoader || projectLoader) && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.contacts}/page/${page_number ? page_number : 1}`}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className={`create_from_input_content`}>
            <label className="create_from_label">Service Name</label>
            <CreatableSelect
              value={values.service_id}
              className="basic-multi-select"
              classNamePrefix="select"
              name="service_id"
              options={serviceDropdown}
              onChange={(e) => {
                handleInputChange(e, "service_id");
              }}
            />
            <span className="err_text">{errors?.service_id || ""}</span>
          </div>
          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Company Name"
            label="Company Name"
            id="company_name"
            name="company_name"
            value={values.company_name}
            onChange={handleInputChange}
            error={errors?.company_name || ""}
          />
          <Input
            className="create_from_input_content input_css"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Name"
            label="Contact Name"
            id="contact_name"
            name="contact_name"
            value={values.contact_name}
            onChange={handleInputChange}
            error={errors?.contact_name || ""}
          />
          <Input
            className="create_from_input_content input_css"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="tel"
            placeholder="Enter Phone Number"
            label="Phone Number"
            id="phone_number"
            name="phone_number"
            value={values.phone_number}
            onChange={handleInputChange}
            error={errors?.phone_number || ""}
            maxLength={10}
          />

          <TextArea
            className="create_from_input_content"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Address"
            label="Address"
            id="contact_address"
            name="contact_address"
            rows={5}
            value={values.contact_address}
            onChange={handleInputChange}
            error={errors?.contact_address || ""}
          />
          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            errorClassName="err_text"
            type="file"
            id="contact_certificate"
            name="contact_certificate"
            label="Certificate"
            accept=".jpg, .jpeg, .png, .pdf"
            onChange={handleInputChange}
            error={errors?.contact_certificate || ""}
            onClick={(e) => (e.target.value = null)}
            imageProps={imageProps(
              values.contact_certificate,
              values.contact_certificate_preview,
              imageRemoveHandler,
              "contact_certificate"
            )}
          />
          {initialCertificatePreview != "" &&
            initialCertificatePreview != undefined && (
              <>
                <h2>&nbsp;</h2>
                <p>
                  <a
                    href={`${initialCertificatePreview}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Certificate
                  </a>
                </p>
              </>
            )}
          <div className="contact_property_main_wrapper">
            <h3>Select Properties</h3>

            {contactProperties?.map((items, i) => {
              return (
                <div className="contact-properties-container" key={i}>
                  <div className={`create_from_input_content`}>
                    <label className="create_from_label">Select Project</label>
                    <CreatableSelect
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Project..."
                      id="project_id"
                      name="project_id"
                      options={projectOptions}
                      onChange={(e) => onProjectChange("project_id", e, i)}
                      menuPortalTarget={document?.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={items?.project_id}
                    />
                    {/* {console.log("inside", items?.project_id)} */}
                  </div>
                  <div className={`create_from_checkbox_content`}>
                    {contactProperties &&
                      contactProperties[i]?.towerList?.length > 0 && (
                        <div>
                          <h3>TowerList</h3>
                          <div className="create-contact-check-container">
                            {contactProperties[i]?.towerList?.map(
                              (tower, j) => (
                                <div
                                  className="create-contact-check-wrapper"
                                  key={j}
                                >
                                  <input
                                    type="checkbox"
                                    name={`tower_${i}_${j}`}
                                    id={`tower_${i}_${j}`}
                                    onChange={(e) => onCheckboxChange(e, i, j)}
                                    checked={tower?.isChecked}
                                    className="contact_checkbox"
                                  />
                                  <label htmlFor={`tower_${i}_${j}`}>
                                    {tower?.name}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="main_temp_class">
                    {items?.towerList && items?.towerList?.length > 0 && (
                      <>
                        {items?.towerList?.map((tower, l) => (
                          <div key={l}>
                            {tower?.isChecked && (
                              <div className="property_tower_wrapper">
                                <div className="property_tower_label">
                                  <label className="property_label">
                                    {tower?.name}
                                  </label>
                                </div>
                                <div className="property_tower_select">
                                  <label className="create_from_label">
                                    Properties
                                  </label>
                                  <CreatableSelect
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select Properties..."
                                    name={`properties_${i}_${l}`}
                                    id={`properties_${i}_${l}`}
                                    isMulti
                                    options={getPropertiesOption(tower)}
                                    onChange={(e) =>
                                      onProjectChange("properties", e, i, l)
                                    }
                                    menuPortalTarget={document?.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                        height: 20,
                                        minHeight: 20,
                                      }),
                                    }}
                                    value={contactProperties[
                                      i
                                    ]?.towerProperties[l]?.properties?.map(
                                      (property) => ({
                                        value: property?.value,
                                        label: property?.label,
                                      })
                                    )}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>

                  <div className="contact_multi_select_wrapper">
                    <div className="project_edit_feature_add_remove_btn_row">
                      {contactProperties?.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => contactsPropertyAddClick(i)}
                          other={addIcon}
                        />
                      )}
                      {contactProperties?.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => contactsPropertyRemoveClick(i)}
                          other={removeIcon}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {duplicateProjectIdError && (
            <span className="error_text">{duplicateProjectIdError}</span>
          )}

          {/* {propertyErr && <span className="error_text">{propertyErr}</span>} */}
          <Button
            className="create_from_bottom_btn col_span_2"
            buttonClassName="comman_btn"
            onClick={createContactHandler}
            text={id ? "Edit Contact" : "Create Contact"}
          />
        </div>
      </div>
    </>
  );
};

export default CreateContactPage;
