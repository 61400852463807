/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { getPlanById } from "../../store/slice/planSlice";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";

const ViewPlanListPage = () => {
  const [nav1, setNav1] = useState(null);
  const location = useLocation();
  const { values } = location?.state;

  const dispatch = useDispatch();
  const params = useParams();
  const { id, page_number } = params;
  const loader = useSelector((state) => state.plan.loader);
  useEffect(() => {
    dispatch(getPlanById(id));
  }, [dispatch, id]);

  const planData = useSelector((state) => state.plan.planDetailId);

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.plans}/page/${page_number}`}
          state={{ locValues: values }}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="about_page_section plan_page_section">
          <div className="about_page_slider">
            <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
              {planData?.main_plan_image && (
                <div className="plan_slider_box">
                  <img
                    src={
                      process.env.REACT_APP_FILE_BASE_URL +
                      "/" +
                      planData?.main_plan_image
                    }
                    alt="plan item"
                    className="plan_slider_box_img"
                  />
                  <a
                    href={`${process.env.REACT_APP_FILE_BASE_URL}/${planData?.main_plan_image}`}
                    target="_blank"
                    className="plan_photo_view"
                    rel="noreferrer"
                  >
                    <i className="fa-solid fa-eye"></i>
                  </a>
                  <div className="plan_slider_box_text_content">
                    <h2 className="plan_slider_heading">Main Plan Image</h2>
                  </div>
                </div>
              )}
              {planData?.documents &&
                JSON.parse(planData?.documents).map((item, i) => {
                  return (
                    <div key={i} className="plan_slider_box">
                      {item?.doc ? (
                        <>
                          {item.doc.split(".pdf").length > 1 ? (
                            <iframe
                              src={
                                process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                item.doc
                              }
                              alt="plan item"
                              className="plan_slider_box_img plan_slider_pdf"
                            />
                          ) : (
                            <img
                              src={
                                process.env.REACT_APP_FILE_BASE_URL +
                                "/" +
                                item.doc
                              }
                              alt="plan item"
                              className="plan_slider_box_img"
                            />
                          )}
                          <a
                            href={`${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`}
                            target="_blank"
                            className="plan_photo_view"
                            rel="noreferrer"
                          >
                            <i className="fa-solid fa-eye"></i>
                          </a>
                        </>
                      ) : null}
                      <div className="plan_slider_box_text_content">
                        <h2 className="plan_slider_heading">Documents</h2>
                        {item.title && (
                          <p className="plan_slider_peragraph">{item.title}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
              {planData?.drawings &&
                JSON.parse(planData?.drawings).map((item, i) => {
                  return (
                    <div key={i} className="plan_slider_box">
                      {item.doc.split(".pdf").length > 1 ? (
                        <iframe
                          src={
                            process.env.REACT_APP_FILE_BASE_URL + "/" + item.doc
                          }
                          alt="plan item"
                          className="plan_slider_box_img plan_slider_pdf"
                        />
                      ) : (
                        <img
                          src={
                            process.env.REACT_APP_FILE_BASE_URL + "/" + item.doc
                          }
                          alt="plan item"
                          className="plan_slider_box_img"
                        />
                      )}
                      <a
                        href={`${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`}
                        target="_blank"
                        className="plan_photo_view"
                        rel="noreferrer"
                      >
                        <i className="fa-solid fa-eye"></i>
                      </a>
                      <div className="plan_slider_box_text_content">
                        <h2 className="plan_slider_heading">Drawings</h2>
                        {item.title && (
                          <p className="plan_slider_peragraph">{item.title}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
              {planData?.legal_documents &&
                JSON.parse(planData?.legal_documents).map((item, i) => {
                  return (
                    <div key={i} className="plan_slider_box">
                      {item.doc.split(".pdf").length > 1 ? (
                        <iframe
                          src={
                            process.env.REACT_APP_FILE_BASE_URL + "/" + item.doc
                          }
                          alt="plan item"
                          className="plan_slider_box_img plan_slider_pdf"
                        />
                      ) : (
                        <img
                          src={
                            process.env.REACT_APP_FILE_BASE_URL + "/" + item.doc
                          }
                          alt="plan item"
                          className="plan_slider_box_img"
                        />
                      )}
                      <a
                        href={`${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`}
                        target="_blank"
                        className="plan_photo_view"
                        rel="noreferrer"
                      >
                        <i className="fa-solid fa-eye"></i>
                      </a>
                      <div className="plan_slider_box_text_content">
                        <h2 className="plan_slider_heading">Legal Documents</h2>
                        {item.title && (
                          <p className="plan_slider_peragraph">{item.title}</p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
          <div className="about_detaile_row">
            <div className="about_detaile_text_content">
              <h2>Project Name </h2>
              <p>{planData?.project_name}</p>
            </div>
            <div className="about_detaile_text_content">
              <h2>Plan Name</h2>
              <p>{planData?.display_name}</p>
            </div>
            <div className="about_detaile_text_content">
              <h2>PlanTitle </h2>
              <p>{planData?.title}</p>
            </div>
            <div className="about_detaile_text_content">
              <h2>Created At </h2>
              <p>
                {moment(planData?.created_at).format("MMMM Do YYYY, h:mm:ss a")}
              </p>
            </div>
            {planData?.updated_at && (
              <div className="about_detaile_text_content">
                <h2>Updated At </h2>
                <p>
                  {moment(planData?.updated_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPlanListPage;
