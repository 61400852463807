import React from "react";
import Input from "../../component/Common/Input";
import { useState } from "react";
import Select from "react-select";
import Button from "../../component/Common/Button";
import {
  getProjectList,
  getTowersByProject,
} from "../../store/slice/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { getPlan } from "../../store/slice/planSlice";
import BulkConfirmationModal from "./BulkConfirmationModal";
import ResetConfirmationModal from "./ResetConfirmationModal";
import { createBulkProperties } from "../../store/slice/customerPropertySlice";

const CreateBulkProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectList = useSelector((state) => state.project.projects);
  const towerList = useSelector((state) => state.project.towerList);
  const loader = useSelector((state) => state.project.loader);
  const planData = useSelector((state) => state.plan.planDetails?.data);
  const loader2 = useSelector((state) => state.plan.loader);

  const [project, setProject] = useState("");
  const [projectTower, setProjectTower] = useState("");
  const [propertiesInputs, setPropertiesInputs] = useState("");
  const [properties, setProperties] = useState([]);
  const [formErr, setFormErr] = useState({});
  const [emptyProperties, setEmptyProperties] = useState("");
  const [disableSelection, setDisableSelection] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  const projectOptions = projectList?.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const towerOptions = towerList?.map((item) => {
    return { value: item.name, label: item.name };
  });

  const planOptions = planData?.map((item) => {
    return { value: item.id, label: item.display_name };
  });

  useEffect(() => {
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  useEffect(() => {
    if (project) {
      dispatch(getTowersByProject({ id: project.id }));
      dispatch(
        getPlan({
          start: 0,
          limit: 10000000,
          project_id: project.id,
        })
      );
    }
  }, [dispatch, project]);

  // onChange Hanlders
  const changeProjectHandler = (e) => {
    setProject(e);
    setProjectTower("");
    setFormErr({ ...formErr, project_err: "" });
  };
  const changeTowerHandler = (e) => {
    setFormErr({ ...formErr, tower_err: "" });
    setProjectTower(e);
  };

  const changePropertiesInputsHandler = (e) => {
    const count = e.target.value;
    setPropertiesInputs(count);
    setFormErr({ ...formErr, inputs_err: "" });
  };

  const changePropertiesHandler = (e, index, input) => {
    setEmptyProperties("");
    const list = [...properties];
    if (input === "plan") {
      const updatedItem = { ...list[index], plan: e };
      list[index] = updatedItem;
    } else {
      const { name, value } = e.target;
      const updatedItem = { ...list[index], [name]: value };
      list[index] = updatedItem;
    }
    setProperties(list);
  };
  //

  // get final Arr of properties
  const getPropertiesValueArr = (propertyArr) => {
    let finalArr = [];

    for (let i = 0; i < propertyArr.length; i++) {
      if (propertyArr[i].property_number !== "") {
        finalArr.push({
          plan_id: propertyArr[i].plan !== "" ? propertyArr[i].plan.value : "",
          property_number: propertyArr[i].property_number,
        });
      }
    }
    return finalArr;
  };

  //Get property Number Array but Number should not be same Validation
  const getPropertyNumberValueShouldNotSame = (propertyNumber) => {
    const propertiesVal = getPropertiesValueArr(properties);
    var finalPropertyNum = [];
    for (let i = 0; i < propertiesVal.length; i++) {
      const currentProperty = propertiesVal[i];
      const propertyNum = currentProperty.property_number.trim();
      if (finalPropertyNum.includes(propertyNum)) {
        return true;
      } else {
        finalPropertyNum.push(propertyNum);
      }
    }
    return false;
  };

  const propertiesInputValidate = () => {
    let errors = {};
    if (project === "") {
      errors = { ...errors, project_err: "Please select Project!" };
    } else if (projectTower === "") {
      errors = { ...errors, tower_err: "Please select Project Tower!" };
    }
    if (+propertiesInputs.trim() === 0 || propertiesInputs.trim() === "") {
      errors = {
        ...errors,
        inputs_err: "Please Enter the Number of properties you want to create!",
      };
    } else if (+propertiesInputs.trim() > 100) {
      errors = {
        ...errors,
        inputs_err: "Maximum 100 bulk properties create at a time!",
      };
    }

    setFormErr(errors);
    return Object.values(errors).every((x) => x === "");
  };

  // Create Number of inputs //
  const createInputHandler = () => {
    if (propertiesInputValidate()) {
      const updatedProperties = [];
      for (let i = 0; i < +propertiesInputs; i++) {
        updatedProperties.push({ property_number: "", plan: "" });
      }
      setDisableSelection(true);
      setProperties(updatedProperties);
    }
  };

  const addInputPropHandler = () => {
    setProperties([...properties, { property_number: "", plan: "" }]);
    setEmptyProperties("");
  };

  // Modal open and close //
  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleModalShow = () => {
    const propertiesVal = getPropertiesValueArr(properties);
    const samePropertyInput = getPropertyNumberValueShouldNotSame(properties);
    if (propertiesVal.length === 0) {
      setEmptyProperties("Please add atleast one property Values!");
    } else if (samePropertyInput) {
      setEmptyProperties("Property numbers should not be same!");
    } else {
      setShowConfirmationModal(true);
    }
  };

  const handleResetModalClose = () => {
    setShowResetModal(false);
  };

  const handleResetModalShow = () => {
    if (getPropertiesValueArr(properties).length === 0) {
      resetFormHandler();
    } else {
      setShowResetModal(true);
    }
  };

  // Reset Form //
  const resetFormHandler = () => {
    setProject("");
    setProjectTower("");
    setPropertiesInputs("");
    setFormErr("");
    setProperties([]);
    setShowResetModal(false);
    setDisableSelection(false);
    setEmptyProperties("");
  };

  // Create Bulk Properties //
  const createBulkPropertiesHandler = () => {
    const propertiesVal = getPropertiesValueArr(properties);
    const data = {
      project_id: project.id,
      tower_name: projectTower.value,
      properties: propertiesVal,
    };
    dispatch(createBulkProperties({ data, navigate }));
  };

  return (
    <>
      {(loader || loader2) && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.customerProperty}/page/1`}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className="customer_properties_number_extra_added_content col_span_2">
            <div className="create_from_input_content">
              <label className="create_from_label">Project</label>
              <Select
                value={project}
                placeholder="Select Project..."
                className="basic-multi-select"
                classNamePrefix="select"
                name="project_id"
                id="project_id"
                options={projectOptions}
                onChange={(e) => changeProjectHandler(e)}
                required
                isDisabled={disableSelection}
              />
              {formErr?.project_err && (
                <span className="err_text">{formErr?.project_err}</span>
              )}
            </div>
            {project ? (
              <div className="create_from_input_content">
                <label className="create_from_label">Tower</label>
                <Select
                  value={projectTower}
                  placeholder="Select Tower..."
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="project_id"
                  id="project_id"
                  options={towerOptions}
                  onChange={(e) => changeTowerHandler(e)}
                  required
                  isDisabled={disableSelection}
                />
                {formErr?.tower_err && (
                  <span className="err_text">{formErr.tower_err}</span>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label long-label-input"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="number"
              placeholder="Enter the Number of Properties"
              label="Number of Properties you want to create!"
              id="propertiesInputs"
              name="propertiesInputs"
              value={propertiesInputs}
              onChange={changePropertiesInputsHandler}
              error={formErr?.inputs_err || ""}
              disabled={disableSelection}
            />
            <div></div>
            <div className="btn-container-create-form">
              <button
                className="create-multi-input-btn"
                onClick={() => createInputHandler()}
                disabled={disableSelection}
              >
                Create
              </button>
              {disableSelection && (
                <button
                  className="create-multi-input-btn"
                  // onClick={() => resetFormHandler()}
                  onClick={() => handleResetModalShow()}
                >
                  Reset
                </button>
              )}
            </div>
            {/* <Button
              className="create_from_bottom_btn"
              buttonClassName="comman_btn"
              onClick={() => createInputHandler()}
              text="Create"
            /> */}
            <span className="col_span_2 border-bottom-span" />
            {properties.map((property, i) => {
              return (
                <>
                  <Input
                    className="create_from_input_content"
                    labelClassName="create_from_label"
                    inputClassName="create_from_input"
                    type="text"
                    placeholder="Enter Property Number"
                    label="Property Number"
                    id="property_number"
                    name="property_number"
                    value={property.property_number}
                    onChange={(e) =>
                      changePropertiesHandler(e, i, "property_number")
                    }
                  />
                  <div className="create_from_input_content">
                    <label className="create_from_label">Plan</label>
                    <div
                      style={{
                        zIndex: "999999" - i,
                        position: "relative",
                      }}
                    >
                      <Select
                        value={property.plan}
                        placeholder="Select Plan..."
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="plan"
                        id="plan"
                        options={planOptions}
                        onChange={(e) => changePropertiesHandler(e, i, "plan")}
                      />
                    </div>
                  </div>

                  <span className="col_span_2 border-bottom-span-minor" />
                  {i === properties.length - 1 && (
                    <>
                      <div></div>
                      <div className="add_more_input_btn_container">
                        <button
                          className="small_comman_btn"
                          onClick={() => addInputPropHandler()}
                        >
                          +
                        </button>
                      </div>
                    </>
                  )}
                </>
              );
            })}
            {emptyProperties && (
              <span className="col_span_2 err_text">{emptyProperties}</span>
            )}

            {properties.length > 0 && (
              <Button
                className="create_from_bottom_btn col_span_2"
                buttonClassName="comman_btn"
                onClick={handleModalShow}
                text="Submit"
                isDisabled={!disableSelection}
              />
            )}
          </div>
        </div>
      </div>
      <BulkConfirmationModal
        projectName={project?.value}
        towerName={projectTower.value}
        handleModalClose={handleModalClose}
        showConfirmationModal={showConfirmationModal}
        createBulkPropertiesHandler={createBulkPropertiesHandler}
      />
      <ResetConfirmationModal
        handleResetModalClose={handleResetModalClose}
        showResetModal={showResetModal}
        createBulkPropertiesHandler={createBulkPropertiesHandler}
        resetFormHandler={resetFormHandler}
      />
    </>
  );
};

export default CreateBulkProperties;
