/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "../../hooks/useForm";
import Select from "react-select";
import { addHandoverChecklist } from "../../store/slice/handoverChecklistSlice";
import { trashIcon, addIcon } from "../../icons"; // Assume addIcon exists
import { getProjectList } from "../../store/slice/projectSlice";

const CreateHandoverChecklist = () => {
  const [inputs, setInputs] = useState([{ title: "", subItems: [] }]); // Each item has a title & sublist
  const [data, setData] = useState({
    project_id: "",
    title: "",
    description: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const value = location?.state?.value;
  const initialFValues = {
    project_id: value?.project_id ? value?.project_id : null,
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const projectList = useSelector((state) => state.project.projects);
  const projectOptions = projectList?.map((item) => ({
    value: item.name,
    label: item.name,
    id: item.id,
  }));

  useEffect(() => {
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  // Convert inputs into JSON string for storage
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      project_id: values?.project_id?.id,
      description: JSON.stringify(inputs),
    }));
  }, [inputs, values.project_id]);

  // Handle main checklist item input change
  const handleInputFieldChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index].title = event.target.value;
    setInputs(newInputs);
  };

  // Handle sublist item input change
  const handleSubItemChange = (parentIndex, subIndex, event) => {
    const newInputs = [...inputs];
    newInputs[parentIndex].subItems[subIndex] = event.target.value;
    setInputs(newInputs);
  };

  // Add a new checklist item
  const addMoreFields = (e) => {
    e.preventDefault();
    setInputs([...inputs, { title: "", subItems: [] }]); // New item with empty sublist
  };

  // Remove a checklist item
  const removeField = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    setInputs(inputs.filter((_, i) => i !== index));
  };

  // Add a sublist item under a main checklist item
  const addSubItem = (index, e) => {
    e.preventDefault();
    const newInputs = [...inputs];
    newInputs[index].subItems.push(""); // Add empty sublist item
    setInputs(newInputs);
  };

  // Remove a sublist item
  const removeSubItem = (parentIndex, subIndex, e) => {
    e.preventDefault();
    e.stopPropagation();

    const newInputs = [...inputs];
    newInputs[parentIndex].subItems.splice(subIndex, 1);
    setInputs(newInputs);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreateChecklist = async (e) => {
    e.preventDefault();
    try {
      if (data?.project_id) {
        await dispatch(addHandoverChecklist(data)).unwrap();
        toast.success("Checklist created successfully", { autoClose: 1500 });
        navigate("/possessionchecklist");
      } else {
        toast.error("Alias Name Required", {
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 1500 });
      console.log(error);
    }
  };

  return (
    <div className="project_edit_main_content">
      <div className="creat_edit_project_btn_row">
        <button className="comman_btn" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div className="project_edit_main_content center_table_body">
        <div className="project_edit_detail_column_content">
          <h2>Add New Checklist</h2>
          <form onSubmit={handleCreateChecklist}>
            {/* Project Selection */}
            <div className="create_from_input_content col_span_2">
              <label className="create_from_label">Select Alias:</label>
              <div style={{ width: "50%", marginBottom: "12px" }}>
                <Select
                  placeholder="Select Project..."
                  value={values.project_id}
                  onChange={(e) => handleInputChange(e, "project_id")}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="project_id"
                  options={projectOptions}
                  menuPortalTarget={document?.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
              {/* Checklist Title */}
              <label className="create_from_label">Checklist Title</label>
              <input
                className="create_from_input"
                type="text"
                placeholder="Enter Checklist Title"
                name="title"
                value={data.title}
                onChange={handleChange}
                required
              />
            </div>

            {/* Checklist Items with Sublist */}
            <div className="create_from_input_content col_span_2">
              <label className="create_from_label">
                Specify the items that customers should verify
              </label>
              {inputs.map((input, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "12px",
                    backgroundColor: index % 2 === 1 ? "#fcf7f9" : "#fff",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px", padding: "7px" }}>
                    <input
                      style={{
                        backgroundColor: index % 2 === 1 ? "#fcf7f9" : "#fff",
                        fontWeight: "bold",
                      }}
                      className="create_from_input"
                      type="text"
                      placeholder="Enter checklist item"
                      value={input.title}
                      onChange={(event) => handleInputFieldChange(index, event)}
                      required
                    />
                    <button
                      className="edit_btn"
                      onClick={(e) => addSubItem(index, e)}
                    >
                      {addIcon} {/* Add Sublist */}
                    </button>
                    <button
                      className={`edit_btn ${
                        inputs.length === 1 ? "disable_btn" : ""
                      }`}
                      onClick={(e) => removeField(index, e)}
                      disabled={inputs.length === 1}
                    >
                      {trashIcon} {/* Remove Main Item */}
                    </button>
                  </div>

                  {/* Sublist Input Fields */}
                  {input.subItems.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      style={{
                        display: "flex",
                        marginLeft: "30px",
                        marginTop: "4px",
                        gap: "5px",
                      }}
                    >
                      <input
                        style={{
                          backgroundColor: index % 2 === 1 ? "#fcf7f9" : "#fff",
                          marginBottom: "5px",
                        }}
                        className="create_from_input"
                        type="text"
                        placeholder="Enter subchecklist item"
                        value={subItem}
                        onChange={(event) =>
                          handleSubItemChange(index, subIndex, event)
                        }
                        required
                      />
                      <button
                        className="edit_btn"
                        onClick={(e) => removeSubItem(index, subIndex, e)}
                      >
                        {trashIcon} {/* Remove Sublist Item */}
                      </button>
                    </div>
                  ))}
                </div>
              ))}

              {/* Add More Checklist Items */}
              <button
                className="edit_btn"
                style={{
                  padding: "10px",
                  fontSize: "12px",
                  fontWeight: "600",
                  letterSpacing: "1.5px",
                }}
                onClick={(e) => addMoreFields(e)}
              >
                Add More
              </button>
            </div>

            {/* Submit Button */}
            <div className="project_submit_bottom_btn center_back_btn">
              <button type="submit" className="comman_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateHandoverChecklist;
