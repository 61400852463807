import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CreateContactPage from "./CreateContactPage";
import Loader from "../../component/Common/Loader";
import { getContactById } from "../../store/slice/contactSlice";

const EditContactPage = () => {
  const [isContactDetail, setIsContactDetail] = useState(true);

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const loader = useSelector((state) => state.contact.loader);

  const contactDetails = useSelector((state) => state.contact.getContactById);

  useEffect(() => {
    if (!contactDetails && id) {
      dispatch(getContactById(id));
    } else {
      setIsContactDetail(false);
    }
  }, [dispatch, id, contactDetails]);

  if (isContactDetail) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <CreateContactPage contactDetails={contactDetails} />
    </>
  );
};

export default EditContactPage;
